import {
    TEST,
    GET_BLOCKCHAIN_STARTED,
    GET_BLOCKCHAIN_SUCCESS,
    GET_BLOCKCHAIN_FAILURE,
    SET_CURRENT_BLOCKCHAIN,
    GET_MAIN_WALLET_DATA_STARTED,
    GET_MAIN_WALLET_DATA_SUCCESS,
    GET_MAIN_WALLET_DATA_FAILURE,
    SET_NETWORK_MODAL_STATUS,
    SET_MESSAGE_MODAL_STATUS,
    SET_CURRENT_WALLET_NETWORK_ID,
    GET_WALLETS_STARTED,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAILURE,
    SET_WALLET_ID,
    CHANGE_NETWORK_STARTED,
    CHANGE_NETWORK_SUCCESS,
    CHANGE_NETWORK_FAILURE,
    GET_WALLET_ASSETS_STARTED,
    GET_WALLET_ASSETS_SUCCESS,
    GET_WALLET_ASSETS_FAILURE,
    GET_TOKENS_STARTED,
    GET_TOKENS_SUCCESS,
    GET_TOKENS_FAILURE,
    GET_CURRENT_WALLET_ASSETS_STARTED,
    GET_CURRENT_WALLET_ASSETS_SUCCESS,
    GET_CURRENT_WALLET_ASSETS_FAILURE,
    ADD_WALLET_ASSETS_STARTED,
    ADD_WALLET_ASSETS_SUCCESS,
    ADD_WALLET_ASSETS_FAILURE,
    REMOVE_WALLET_ASSETS_STARTED,
    REMOVE_WALLET_ASSETS_SUCCESS,
    REMOVE_WALLET_ASSETS_FAILURE,
    GET_CURRENCY_GROUPS_STARTED,
    GET_CURRENCY_GROUPS_SUCCESS,
    GET_CURRENCY_GROUPS_FAILURE,
    SET_CURRENCY_GROUP,
    CREATE_NEW_ACCOUNT_STARTED,
    CREATE_NEW_ACCOUNT_SUCCESS,
    CREATE_NEW_ACCOUNT_FAILURE,
    GET_WALLET_ASSETS_HISTORY_STARTED,
    GET_WALLET_ASSETS_HISTORY_SUCCESS,
    GET_WALLET_ASSETS_HISTORY_FAILURE,
    GET_WALLET_ASSETS_FEE_STARTED,
    GET_WALLET_ASSETS_FEE_SUCCESS,
    GET_WALLET_ASSETS_FEE_FAILURE,
    SEND_CRYPTO_STARTED,
    SEND_CRYPTO_SUCCESS,
    SEND_CRYPTO_FAILURE,
    CHANGE_ACCOUNT_STATUS,
    GET_VALIDATE_ADDRESS_STATUS_STARTED,
    GET_VALIDATE_ADDRESS_STATUS_SUCCESS,
    GET_VALIDATE_ADDRESS_STATUS_FAILURE,
    SET_SEND_MODAL_STATUS,
    GET_TRANSACTION_DETAILS_STARTED,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_TRANSACTION_DETAILS_FAILURE,
    GET_CONNECT_CODE_STARTED,
    GET_CONNECT_CODE_SUCCESS,
    GET_CONNECT_CODE_FAILURE
} from "./types";


const initialState = {
    test: false,
    currentBlockchain: localStorage.getItem('owt-cb_id') ? Number(localStorage.getItem('owt-cb_id')) : false,
    walletId: localStorage.getItem('owt-w_id') ? Number(localStorage.getItem('owt-w_id')) : false,
    accountStatus: false,
    allBlockchainStarted: false,
    allBlockchain: false,
    allBlockchainErr: false,
    mainWalletDataStarted: false,
    mainWalletData: false,
    mainWalletDataErr: false,
    networkModalStatus: false,
    messageModalStatus: false,
    currentWalletNetworkId: false,
    allWalletsStarted: false,
    allWallets: false,
    allWalletsErr: false,
    changeNetworkStarted: false,
    changeNetwork: false,
    changeNetworkErr: false,
    walletAssetsStarted: false,
    walletAssets: false,
    walletAssetsErr: false,
    walletTokensStarted: false,
    walletTokens: false,
    walletTokensErr: false,
    currentWalletAssetsStarted: false,
    currentWalletAssets: false,
    currentWalletAssetsErr: false,
    addWalletAssetsStarted: false,
    addWalletAssets: false,
    addWalletAssetsErr: false,
    removeWalletAssetsStarted: false,
    removeWalletAssets: false,
    removeWalletAssetsErr: false,
    currencyGroupsStarted: false,
    currencyGroups: false,
    currencyGroupsErr: false,
    currentCurrencyGroup: false,
    createNewAccountStatusStarted: false,
    createNewAccountStatus: false,
    createNewAccountStatusErr: false,
    walletAssetsHistoryStarted: false,
    walletAssetsHistory: false,
    walletAssetsHistoryErr: false,
    walletAssetsFeeStarted: false,
    walletAssetsFee: false,
    walletAssetsFeeErr: false,
    sendCryptoStatusStarted: false,
    sendCryptoStatus: false,
    sendCryptoStatusErr: false,
    validateAddressStatusStarted: false,
    validateAddressStatus: false,
    validateAddressStatusErr: false,
    sendModalStatus: {status: false, hash: false},
    transactionDetailsDataStarted: false,
    transactionDetailsData: false,
    transactionDetailsDataErr: false,
    connectCodeStatusStarted: false,
    connectCodeStatus: false,
    connectCodeStatusErr: false,
};

export function walletReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case CHANGE_ACCOUNT_STATUS:
            return {
                ...state,
                accountStatus: action.payload
            };
        case SET_WALLET_ID:
            return {
                ...state,
                walletId: action.payload
            };
        case SET_NETWORK_MODAL_STATUS:
            return {
                ...state,
                networkModalStatus: action.payload
            };
        case SET_CURRENCY_GROUP:
            return {
                ...state,
                currentCurrencyGroup: action.payload
            };
        case SET_MESSAGE_MODAL_STATUS:
            return {
                ...state,
                messageModalStatus: action.payload
            };
        case SET_SEND_MODAL_STATUS:
            return {
                ...state,
                sendModalStatus: action.payload
            };
        case SET_CURRENT_WALLET_NETWORK_ID:
            return {
                ...state,
                currentWalletNetworkId: action.payload
            };
        case SET_CURRENT_BLOCKCHAIN:
            return {
                ...state,
                currentBlockchain: action.payload
            };
        case GET_BLOCKCHAIN_STARTED:
            return {
                ...state,
                allBlockchainStarted: true
            };
        case GET_BLOCKCHAIN_SUCCESS:
            return {
                ...state,
                allBlockchain: action.payload,
                allBlockchainStarted: false
            };
        case GET_BLOCKCHAIN_FAILURE:
            return {
                ...state,
                allBlockchainErr: action.payload,
                allBlockchainStarted: false
            };
        case GET_MAIN_WALLET_DATA_STARTED:
            return {
                ...state,
                mainWalletDataStarted: true
            };
        case GET_MAIN_WALLET_DATA_SUCCESS:
            return {
                ...state,
                mainWalletData: action.payload,
                mainWalletDataStarted: false
            };
        case GET_MAIN_WALLET_DATA_FAILURE:
            return {
                ...state,
                mainWalletDataErr: action.payload,
                mainWalletDataStarted: false
            };
        case GET_WALLETS_STARTED:
            return {
                ...state,
                allWalletsStarted: true
            };
        case GET_WALLETS_SUCCESS:
            return {
                ...state,
                allWallets: action.payload,
                allWalletsStarted: false
            };
        case GET_WALLETS_FAILURE:
            return {
                ...state,
                allWalletsErr: action.payload,
                allWalletsStarted: false
            };
        case CHANGE_NETWORK_STARTED:
            return {
                ...state,
                changeNetworkStarted: true
            };
        case CHANGE_NETWORK_SUCCESS:
            return {
                ...state,
                changeNetwork: action.payload,
                changeNetworkStarted: false
            };
        case CHANGE_NETWORK_FAILURE:
            return {
                ...state,
                changeNetworkErr: action.payload,
                changeNetworkStarted: false
            };
        case GET_WALLET_ASSETS_STARTED:
            return {
                ...state,
                walletAssetsStarted: true
            };
        case GET_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                walletAssets: action.payload,
                walletAssetsStarted: false
            };
        case GET_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                walletAssetsErr: action.payload,
                walletAssetsStarted: false
            };
        case GET_TOKENS_STARTED:
            return {
                ...state,
                walletTokensStarted: true
            };
        case GET_TOKENS_SUCCESS:
            return {
                ...state,
                walletTokens: action.payload,
                walletTokensStarted: false
            };
        case GET_TOKENS_FAILURE:
            return {
                ...state,
                walletTokensErr: action.payload,
                walletTokensStarted: false
            };
        case GET_CURRENT_WALLET_ASSETS_STARTED:
            return {
                ...state,
                currentWalletAssetsStarted: true
            };
        case GET_CURRENT_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                currentWalletAssets: action.payload,
                currentWalletAssetsStarted: false
            };
        case GET_CURRENT_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                currentWalletAssetsErr: action.payload,
                currentWalletAssetsStarted: false
            };
        case ADD_WALLET_ASSETS_STARTED:
            return {
                ...state,
                addWalletAssetsStarted: true
            };
        case ADD_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                addWalletAssets: action.payload,
                addWalletAssetsStarted: false
            };
        case ADD_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                addWalletAssetsErr: action.payload,
                addWalletAssetsStarted: false
            };
        case REMOVE_WALLET_ASSETS_STARTED:
            return {
                ...state,
                removeWalletAssetsStarted: true
            };
        case REMOVE_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                removeWalletAssets: action.payload,
                removeWalletAssetsStarted: false
            };
        case REMOVE_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                removeWalletAssetsErr: action.payload,
                removeWalletAssetsStarted: false
            };
        case GET_CURRENCY_GROUPS_STARTED:
            return {
                ...state,
                currencyGroupsStarted: true
            };
        case GET_CURRENCY_GROUPS_SUCCESS:
            return {
                ...state,
                currencyGroups: action.payload,
                currencyGroupsStarted: false
            };
        case GET_CURRENCY_GROUPS_FAILURE:
            return {
                ...state,
                currencyGroupsErr: action.payload,
                currencyGroupsStarted: false
            };
        case CREATE_NEW_ACCOUNT_STARTED:
            return {
                ...state,
                createNewAccountStatusStarted: true
            };
        case CREATE_NEW_ACCOUNT_SUCCESS:
            return {
                ...state,
                createNewAccountStatus: action.payload,
                createNewAccountStatusStarted: false
            };
        case CREATE_NEW_ACCOUNT_FAILURE:
            return {
                ...state,
                createNewAccountStatusErr: action.payload,
                createNewAccountStatusStarted: false
            };
        case GET_WALLET_ASSETS_HISTORY_STARTED:
            return {
                ...state,
                walletAssetsHistoryStarted: true
            };
        case GET_WALLET_ASSETS_HISTORY_SUCCESS:
            return {
                ...state,
                walletAssetsHistory: action.payload,
                walletAssetsHistoryStarted: false
            };
        case GET_WALLET_ASSETS_HISTORY_FAILURE:
            return {
                ...state,
                walletAssetsHistoryErr: action.payload,
                walletAssetsHistoryStarted: false
            };
        case GET_WALLET_ASSETS_FEE_STARTED:
            return {
                ...state,
                walletAssetsFeeStarted: true
            };
        case GET_WALLET_ASSETS_FEE_SUCCESS:
            return {
                ...state,
                walletAssetsFee: action.payload,
                walletAssetsFeeStarted: false
            };
        case GET_WALLET_ASSETS_FEE_FAILURE:
            return {
                ...state,
                walletAssetsFeeErr: action.payload,
                walletAssetsFeeStarted: false
            };
        case SEND_CRYPTO_STARTED:
            return {
                ...state,
                sendCryptoStatusStarted: true
            };
        case SEND_CRYPTO_SUCCESS:
            return {
                ...state,
                sendCryptoStatus: action.payload,
                sendCryptoStatusStarted: false
            };
        case SEND_CRYPTO_FAILURE:
            return {
                ...state,
                sendCryptoStatusErr: action.payload,
                sendCryptoStatusStarted: false
            };
        case GET_VALIDATE_ADDRESS_STATUS_STARTED:
            return {
                ...state,
                validateAddressStatusStarted: true
            };
        case GET_VALIDATE_ADDRESS_STATUS_SUCCESS:
            return {
                ...state,
                validateAddressStatus: action.payload,
                validateAddressStatusStarted: false
            };
        case GET_VALIDATE_ADDRESS_STATUS_FAILURE:
            return {
                ...state,
                validateAddressStatusErr: action.payload,
                validateAddressStatusStarted: false
            };
        case GET_TRANSACTION_DETAILS_STARTED:
            return {
                ...state,
                transactionDetailsDataStarted: true
            };
        case GET_TRANSACTION_DETAILS_SUCCESS:
            return {
                ...state,
                transactionDetailsData: action.payload,
                transactionDetailsDataStarted: false
            };
        case GET_TRANSACTION_DETAILS_FAILURE:
            return {
                ...state,
                transactionDetailsDataErr: action.payload,
                transactionDetailsDataStarted: false
            };
        case GET_CONNECT_CODE_STARTED:
            return {
                ...state,
                connectCodeStatusStarted: true
            };
        case GET_CONNECT_CODE_SUCCESS:
            return {
                ...state,
                connectCodeStatus: action.payload,
                connectCodeStatusStarted: false
            };
        case GET_CONNECT_CODE_FAILURE:
            return {
                ...state,
                connectCodeStatusErr: action.payload,
                connectCodeStatusStarted: false
            };
        default:
            return state;
    }
}

