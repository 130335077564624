import axios from 'axios';

import {
    TEST,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    SET_AUTHORIZATION_STATUS,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    GET_CONFIRMATION_PHRASE_STARTED,
    GET_CONFIRMATION_PHRASE_SUCCESS,
    GET_CONFIRMATION_PHRASE_FAILURE,
    SET_CONFIRMATION_PHRASE_STARTED,
    SET_CONFIRMATION_PHRASE_SUCCESS,
    SET_CONFIRMATION_PHRASE_FAILURE,
    SET_PHRASE_STATUS,
    SET_UUID,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SET_AGREEMENT_STATUS,
    IMPORT_WALLET_STARTED,
    IMPORT_WALLET_SUCCESS,
    IMPORT_WALLET_FAILURE
} from "./types";

import {api} from "../../api";
const host = api.host;

export const test = data => ({
    type: TEST,
    payload: data
});

export const setAuthorizationStatus = status => ({
    type: SET_AUTHORIZATION_STATUS,
    payload: status
});

export const setAgreementStatus = status => ({
    type: SET_AGREEMENT_STATUS,
    payload: status
});

export const setPhraseStatus = status => ({
    type: SET_PHRASE_STATUS,
    payload: status
});

export const setUuid = uuid => ({
    type: SET_UUID,
    payload: uuid
});

export const setPhraseToConfirmation = (data) => {
    let url = host + api.authorization.import;
    const token = localStorage.getItem('owt');

    return dispatch => {
        dispatch(setPhraseStarted());
        axios.post(url, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                // localStorage.setItem('omt-phrase-status', true);
                // dispatch(setAuthorizationStatus({authorization: true, phrase: true}));
                dispatch(setPhraseSuccess(res.data.success));
                localStorage.setItem('owt', res.data.response.token);
                dispatch(setAuthorizationStatus(true));
            })
            .catch(err => {
                dispatch(setPhraseFailure(err.response.data.errors));
            });
    };
};

const setPhraseStarted = () => ({
    type: SET_PHRASE_STARTED
});

export const setPhraseSuccess = serverReport => ({
    type: SET_PHRASE_SUCCESS,
    payload: serverReport
});

export const setPhraseFailure = error => ({
    type: SET_PHRASE_FAILURE,
    payload: error
});


export const registration = (data) => {
    let url = host + api.authorization.registration;

    return dispatch => {
        dispatch(registrationStarted());
        axios.post(url, data)
            .then(res => {
                localStorage.setItem('owt', res.data.response.token);
                localStorage.setItem('owt-uuid', res.data.response.uuid);
                dispatch(registrationSuccess(res.data.success));
                dispatch(setAuthorizationStatus(true));
                dispatch(setUuid(res.data.response.uuid));
            })
            .catch(err => {
                dispatch(registrationFailure(err.response.data.errors));
            });
    };
};

const registrationStarted = () => ({
    type: REGISTRATION_STARTED
});

export const registrationSuccess = serverReport => ({
    type: REGISTRATION_SUCCESS,
    payload: serverReport
});

export const registrationFailure = error => ({
    type: REGISTRATION_FAILURE,
    payload: error
});


export const getPhrase = () => {
    let url = host + api.authorization.getPhrase;
    const token = localStorage.getItem('owt');

    return dispatch => {
        dispatch(getPhraseStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getPhraseSuccess(res.data.response?.phrase));
            })
            .catch(err => {
                dispatch(getPhraseFailure(err.response.data.errors));
            });
    };
};

const getPhraseStarted = () => ({
    type: GET_PHRASE_STARTED
});

export const getPhraseSuccess = serverReport => ({
    type: GET_PHRASE_SUCCESS,
    payload: serverReport
});

export const getPhraseFailure = error => ({
    type: GET_PHRASE_FAILURE,
    payload: error
});


export const getConfirmationPhrase = () => {
    let url = host + api.authorization.getConfirmationPhrase;
    const token = localStorage.getItem('owt');

    return dispatch => {
        dispatch(getConfirmationPhraseStarted());
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                dispatch(getConfirmationPhraseSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getConfirmationPhraseFailure(err.response.data.errors));
            });
    };
};

const getConfirmationPhraseStarted = () => ({
    type: GET_CONFIRMATION_PHRASE_STARTED
});

export const getConfirmationPhraseSuccess = serverReport => ({
    type: GET_CONFIRMATION_PHRASE_SUCCESS,
    payload: serverReport
});

export const getConfirmationPhraseFailure = error => ({
    type: GET_CONFIRMATION_PHRASE_FAILURE,
    payload: error
});

export const setConfirmationPhrase = (data) => {
    let url = host + api.authorization.getConfirmationPhrase;
    const token = localStorage.getItem('owt');

    return dispatch => {
        dispatch(setConfirmationPhraseStarted());
        axios.post(url, data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                localStorage.setItem('owt-ps', res.data.success);
                dispatch(setConfirmationPhraseSuccess(res.data.success));
                dispatch(setPhraseStatus(true));
            })
            .catch(err => {
                dispatch(setConfirmationPhraseFailure(err.response.data.errors));
            });
    };
};

const setConfirmationPhraseStarted = () => ({
    type: SET_CONFIRMATION_PHRASE_STARTED
});

export const setConfirmationPhraseSuccess = serverReport => ({
    type: SET_CONFIRMATION_PHRASE_SUCCESS,
    payload: serverReport
});

export const setConfirmationPhraseFailure = error => ({
    type: SET_CONFIRMATION_PHRASE_FAILURE,
    payload: error
});


export const login = (data) => {
    let url = host + api.authorization.login;

    return dispatch => {
        dispatch(loginStarted());
        axios.post(url, data)
            .then(res => {
                localStorage.setItem('owt-ps', res.data.response?.backup_phrase_copied);
                localStorage.setItem('owt', res.data.response.token);
                dispatch(loginSuccess(res.data.success));
                dispatch(setAuthorizationStatus(true));
                dispatch(setPhraseStatus(res.data.response?.backup_phrase_copied));
            })
            .catch(err => {
                dispatch(loginFailure(err.response.data.errors));
            });
    };
};

const loginStarted = () => ({
    type: LOGIN_STARTED
});

export const loginSuccess = serverReport => ({
    type: LOGIN_SUCCESS,
    payload: serverReport
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error
});

export const importWallet = (data) => {
    let url = host + api.authorization.import;

    return dispatch => {
        dispatch(importWalletStarted());
        axios.post(url, data)
            .then(res => {
                localStorage.setItem('owt-ps', res.data.response?.backup_phrase_copied);
                localStorage.setItem('owt', res.data.response.token);
                localStorage.setItem('owt-uuid', res.data.response.uuid);
                dispatch(setPhraseStatus(res.data.response?.backup_phrase_copied));
                dispatch(importWalletSuccess(res.data.success));
                dispatch(setAuthorizationStatus(true));
                dispatch(setUuid(res.data.response.uuid));
            })
            .catch(err => {
                dispatch(importWalletFailure(err.response.data.errors));
            });
    };
};

const importWalletStarted = () => ({
    type: IMPORT_WALLET_STARTED
});

export const importWalletSuccess = serverReport => ({
    type: IMPORT_WALLET_SUCCESS,
    payload: serverReport
});

export const importWalletFailure = error => ({
    type: IMPORT_WALLET_FAILURE,
    payload: error
});



