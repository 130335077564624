import React, {useState} from "react";
import Styles from './styles.module.scss';
import addIcon from '../../theme/assets/svg/omt_add_icon.svg';
import searchIcon from '../../theme/assets/svg/omt_search_icon.svg';
import {useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {useNavigate} from "react-router-dom";



const WalletAssets = () => {
    const navigate = useNavigate();

    const walletAssets = useSelector(store => store.wallet.walletAssets);

    const [searchStatus, setSearchStatus] = useState(false);
    const [searchData, setSearchData] = useState('');

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.navRow}`}>
                    <div className={`${Styles.title5}`}>Assets</div>
                    <div className={`${Styles.navWrap}`}>
                        <div className={`${Styles.navItem}`} onClick={()=>{setSearchStatus(true)}}>
                            <img src={searchIcon} alt="search"/>
                        </div>
                        <div className={`${Styles.navItem}`} onClick={()=>{navigate('/wallet-add-token')}}>
                            <img src={addIcon} alt="add token"/>
                        </div>
                        {searchStatus ?
                            <div className={`${Styles.absSearchWrap}`}>
                                <div className={`${Styles.absCloseBtn}`} onClick={()=>{setSearchStatus(false)}}>
                                    <div/>
                                    <div/>
                                </div>
                                <input type="text" value={searchData} onChange={(e)=>{setSearchData(e.target.value)}}/>
                            </div> : null
                        }
                    </div>
                </div>
                <div className={`${Styles.assetsTable}`}>
                    <div className={`${Styles.assetsTableTitleRow}`}>
                        <div className={`${Styles.assetsTableTitleRowItem}`}>Token</div>
                        <div className={`${Styles.assetsTableTitleRowItem}`}>Amount</div>
                    </div>
                    <div className={`${Styles.assetsTableContentWrap}`}>
                        {walletAssets ? walletAssets.map((item, index)=>{
                            return (
                                <div className={`${Styles.assetsTableContentWrapRow}`} key={'walletAssets' + index} onClick={()=>{navigate(`/wallet-assets/${item.id}`)}}>
                                    <div className={`${Styles.tokenWrap}`}>
                                        <div className={`${Styles.imgBox}`}>
                                            <CryptoIcon short_name={item.currency_code} mediumSize={true}/>
                                        </div>
                                        <div className={`${Styles.dataBox}`}>
                                            <div className={`${Styles.dataBoxName}`}>{item.currency_code}</div>
                                            {item.currency_rate > 0 ? <div className={`${Styles.dataBoxRate}`}>{(item.currency_rate * 1).toFixed(2)}$</div> : null}
                                        </div>
                                    </div>
                                    <div className={`${Styles.amountWrap}`}>
                                        <div className={`${Styles.amount}`}>{item.amount * 1} {item.currency_code}</div>
                                        <div className={`${Styles.amountRate}`}>{((item.amount * 1) * (item.currency_rate * 1)).toFixed(2)}$</div>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletAssets;
