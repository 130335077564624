import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';

import checkIconA from '../../../theme/assets/svg/omt_check_iconA.svg';
import checkIconP from '../../../theme/assets/svg/omt_check_iconP.svg';
import {useDispatch} from "react-redux";


const CustomInput = (props) => {
    const {type, title, note, bottomNote, placeholder, errStatus, errType, value, setValue, firstPassword, errAction, standardErrData} = props;

    const dispatch = useDispatch();

    const [uppercaseStatus, setUppercaseStatus] = useState(false);
    const [lowercaseStatus, setLowercaseStatus] = useState(false);
    const [numberStatus, setNumberStatus] = useState(false);
    const [longStatus, setLongStatus] = useState(false);
    const [matchStatus, setMatchStatus] = useState(false);
    const [nameStatus, setNameStatus] = useState(false);

    const errContentHandler = () =>{
        switch (errType){
            case 'confirm':
               return (
                   <div className={`${Styles.inputErrWrapConfirm}`}>
                       <div className={`${Styles.errItem}`}>
                           <div className={`${Styles.errItemIconWrap}`}>
                               <img src={matchStatus ? checkIconA : checkIconP} alt="check icon"/>
                           </div>
                           <div className={`${Styles.errItemText}`}>Passwords match</div>
                       </div>
                   </div>
               );
            case 'name':
                return (
                    <div className={`${Styles.inputErrWrapConfirm}`}>
                        <div className={`${Styles.errItem}`}>
                            <div className={`${Styles.errItemIconWrap}`}>
                                <img src={nameStatus ? checkIconA : checkIconP} alt="check icon"/>
                            </div>
                            <div className={`${Styles.errItemText}`}>2-14 characters</div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={`${Styles.inputErrWrap}`}>
                        <div className={`${Styles.errItem}`}>
                            <div className={`${Styles.errItemIconWrap}`}>
                                <img src={uppercaseStatus ? checkIconA : checkIconP} alt="check icon"/>
                            </div>
                            <div className={`${Styles.errItemText}`}>Uppercase</div>
                        </div>
                        <div className={`${Styles.errItem}`}>
                            <div className={`${Styles.errItemIconWrap}`}>
                                <img src={lowercaseStatus ? checkIconA : checkIconP} alt="check icon"/>
                            </div>
                            <div className={`${Styles.errItemText}`}>Lowercase</div>
                        </div>
                        <div className={`${Styles.errItem}`}>
                            <div className={`${Styles.errItemIconWrap}`}>
                                <img src={numberStatus ? checkIconA : checkIconP} alt="check icon"/>
                            </div>
                            <div className={`${Styles.errItemText}`}>Number</div>
                        </div>
                        <div className={`${Styles.errItem}`}>
                            <div className={`${Styles.errItemIconWrap}`}>
                                <img src={longStatus ? checkIconA : checkIconP} alt="check icon"/>
                            </div>
                            <div className={`${Styles.errItemText}`}>≥8 characters</div>
                        </div>
                    </div>
                );
        }
    }

    useEffect(()=>{
        let regexUpper = /[A-Z]/;
        let regexLower = /[a-z]/;
        let regexNumber = /\d/;

        setUppercaseStatus(!!regexUpper.test(value));
        setLowercaseStatus(!!regexLower.test(value));
        setNumberStatus(!!regexNumber.test(value));
        setLongStatus(value.length > 7);
        setMatchStatus(value === firstPassword);
        setNameStatus(value.length > 1 && value.length < 15)

    },[firstPassword, value])
    
    useEffect(()=>{
        if (errAction){
            if (errType === 'confirm'){
                if (matchStatus){
                    errAction(true)
                } else {
                    errAction(false)
                }
            } else if(errType === 'name'){
                if (nameStatus){
                    errAction(true)
                } else {
                    errAction(false)
                }
            } else {
                if (uppercaseStatus && lowercaseStatus && numberStatus && longStatus){
                    errAction(true)
                } else {
                    errAction(false)
                }
            }
        }

    },[errAction, errType, longStatus, lowercaseStatus, matchStatus, nameStatus, numberStatus, uppercaseStatus])

    return (
        <div className={`${Styles.inputWrap}`}>
            {title ? <div className={`${Styles.inputTitle}`}>{title}</div> : null}
            {note ? <div className={`${Styles.inputNote}`}>{note}</div> : null}
            <input
                className={`${standardErrData?.message?.[standardErrData?.name] ? Styles.inputWithErr : null}`}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(e)=>{
                    if (standardErrData?.message?.[standardErrData?.name]){
                        delete standardErrData?.message?.[standardErrData?.name];
                        dispatch(standardErrData?.action(standardErrData?.message));
                    }
                    setValue(e.target.value);
                }}
            />
            {bottomNote ?
                <div className={`${Styles.bottomNoteWrap}`}>
                    {bottomNote.map((item, index)=>{
                        return (
                            <div className={`${Styles.bottomNoteWrapItem}`} key={'bottomNoteItem' + index}>{item}</div>
                        )
                    })}
                </div>
                : null}
            {errStatus ? errContentHandler() : null}
            {standardErrData?.message?.[standardErrData?.name] ? <div className={`${Styles.absErr} ${bottomNote ? Styles.absErrWBN : null}`}>{standardErrData?.message?.[standardErrData?.name]}</div> : null}
        </div>
    );
};

export default CustomInput;
