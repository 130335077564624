import React, {useEffect} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setMessageModalStatus} from "../../store/wallet/action";

import successIcon from '../../theme/assets/svg/omt_modal_success_icon.svg';




const MessageModal = () => {
    const dispatch = useDispatch();
    const modalStatus = useSelector(store => store.wallet.messageModalStatus);


    const closeModal = () =>{
        dispatch(setMessageModalStatus(false));
    }
    
    useEffect(()=>{
        if (modalStatus){
            let timer = setTimeout(()=>{
                dispatch(setMessageModalStatus(false));
            }, 2000)

            return () => {
                console.log('очитска')
                clearTimeout(timer);
            }
        }
    }, [dispatch, modalStatus])

    return (
        <Popup
            open={!!modalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className='typeMessage'
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.textWrap}`}>
                    <div className={`${Styles.textImgWrap}`}>
                        <img src={successIcon} alt={modalStatus ? modalStatus : ''}/>
                    </div>
                    <div className={`${Styles.text}`}>{modalStatus}!</div>
                </div>
            </div>
        </Popup>
    );
};

export default MessageModal;
