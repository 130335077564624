import React from "react";
import Styles from './styles.module.scss';

import CustomRouter from "../../components/customRouter";
import WalletReceiveContent from "../../components/walletReceiveContent";
import {useParams} from "react-router-dom";



const WalletReceivePage = () => {
    const {address, blockchain} = useParams();

    return (
        <section className={`${Styles.container}`}>
            <WalletReceiveContent address={address} blockchain={blockchain}/>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletReceivePage;