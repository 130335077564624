export const TEST = 'TEST';

export const SET_AUTHORIZATION_STATUS = 'SET_AUTHORIZATION_STATUS';
export const SET_PHRASE_STATUS = 'SET_PHRASE_STATUS';
export const SET_UUID = 'SET_UUID';
export const SET_AGREEMENT_STATUS = 'SET_AGREEMENT_STATUS';

export const SET_PHRASE_STARTED = 'SET_PHRASE_STARTED';
export const SET_PHRASE_SUCCESS = 'SET_PHRASE_SUCCESS';
export const SET_PHRASE_FAILURE = 'SET_PHRASE_FAILURE';

export const GET_PHRASE_STARTED = 'GET_PHRASE_STARTED';
export const GET_PHRASE_SUCCESS = 'GET_PHRASE_SUCCESS';
export const GET_PHRASE_FAILURE = 'GET_PHRASE_FAILURE';

export const GET_CONFIRMATION_PHRASE_STARTED = 'GET_CONFIRMATION_PHRASE_STARTED';
export const GET_CONFIRMATION_PHRASE_SUCCESS = 'GET_CONFIRMATION_PHRASE_SUCCESS';
export const GET_CONFIRMATION_PHRASE_FAILURE = 'GET_CONFIRMATION_PHRASE_FAILURE';

export const SET_CONFIRMATION_PHRASE_STARTED = 'SET_CONFIRMATION_PHRASE_STARTED';
export const SET_CONFIRMATION_PHRASE_SUCCESS = 'SET_CONFIRMATION_PHRASE_SUCCESS';
export const SET_CONFIRMATION_PHRASE_FAILURE = 'SET_CONFIRMATION_PHRASE_FAILURE';

export const REGISTRATION_STARTED = 'REGISTRATION_STARTED';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const IMPORT_WALLET_STARTED = 'IMPORT_WALLET_STARTED';
export const IMPORT_WALLET_SUCCESS = 'IMPORT_WALLET_SUCCESS';
export const IMPORT_WALLET_FAILURE = 'IMPORT_WALLET_FAILURE';


