import React, {useState} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CustomBtn from "../_UI/customBtn";
import {useTranslation} from "react-i18next";
import CustomInput from "../_UI/customInput";
import {useDispatch} from "react-redux";
import {registration} from "../../store/main/action";
import {useNavigate} from "react-router-dom";


const CreateWalletForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');


    const [errNameCompleted, setErrNameCompleted] = useState(false);
    const [errCompleted, setErrCompleted] = useState(false);
    const [errMatchCompleted, setErrMatchCompleted] = useState(false);

    const mainBtnHandler = () =>{
        if (errCompleted && errMatchCompleted && errNameCompleted){
            dispatch(registration({wallet_name: name, password: password, password_confirmation: password_confirmation}))
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.formWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.backIconWrap}`} onClick={()=>{navigate('/')}}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Create HD Wallet</div>
                    </div>
                    <div className={`${Styles.titleNote}`}>
                        <div className={`${Styles.titleNoteItem}`}>OMT Wallet User Terms and Privacy Policy</div>
                        <div className={`${Styles.titleNoteItem}`}>* HD wallet accounts on both TRON and <span>EVM networks</span> will be created.</div>
                        <div className={`${Styles.titleNoteItem}`}>* You have full control over your crypto assets as your mnemonic is encrypted and securely stored on your device.</div>
                    </div>
                    <div className={`${Styles.formBox}`}>
                        <CustomInput
                            type='text'
                            title='Wallet Name'
                            placeholder='Wallet 1'
                            errType='name'
                            errStatus={true}
                            value={name}
                            setValue={setName}
                            errAction={setErrNameCompleted}
                        />
                        <CustomInput
                            type='password'
                            title='Password'
                            note='Your password is essential for unlocking your wallet and accessing your mnemonic, private key, and keystore. Please keep it safe as TronLink does not store your password and cannot help you recover it if lost.'
                            placeholder='Enter a password'
                            errStatus={true}
                            value={password}
                            setValue={setPassword}
                            errAction={setErrCompleted}
                        />
                        <CustomInput
                            type='password'
                            placeholder='Confirm password'
                            errStatus={true}
                            errType='confirm'
                            firstPassword={password}
                            value={password_confirmation}
                            setValue={setPassword_confirmation}
                            errAction={setErrMatchCompleted}
                        />
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={t('Создать кошелек')} link='/create-success' btnType={errCompleted && errMatchCompleted && errNameCompleted ? 'wb' : 'db'} action={mainBtnHandler}/>
                </div>
            </div>
        </section>
    );
};

export default CreateWalletForm;
