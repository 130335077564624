import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import radioIconActive from '../../theme/assets/svg/omt_radio_icon_active.svg';
import radioIconPassive from '../../theme/assets/svg/omt_radio_icon_passive.svg';
import CryptoIcon from "../../components/_UI/cryptoIcon";
import CustomBtn from "../../components/_UI/customBtn";





const WalletImportStep2 = () => {
    const navigate = useNavigate();

    const [mainRowStatus, setMainRowStatus] = useState(false);
    const [otherRowStatus, setOtherRowStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet-add')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.mainRow}`}>
                        <div className={`${Styles.cryptoWrap}`}>
                            <CryptoIcon short_name={'Tron'} mediumSize={true}/>
                            <div className={`${Styles.cryptoName}`}>TRX</div>
                        </div>
                        <div
                            className={`${Styles.radioBtn}`}
                            onClick={()=>{
                                setMainRowStatus(true);
                                setOtherRowStatus(false);
                            }}
                        >
                            <img src={mainRowStatus ? radioIconActive : radioIconPassive} alt="radio checkbox"/>
                        </div>
                    </div>
                    <div className={`${Styles.otherRow}`}>
                        <div className={`${Styles.otherRowTitleWrap}`}>
                            <div className={`${Styles.otherRowTitle}`}>EVM Networks</div>
                            <div
                                className={`${Styles.radioBtn}`}
                                onClick={()=>{
                                    setOtherRowStatus(true);
                                    setMainRowStatus(false);
                                }}
                            >
                                <img src={otherRowStatus ? radioIconActive : radioIconPassive} alt="radio checkbox"/>
                            </div>
                        </div>
                        <div className={`${Styles.otherRowNetworkWrap}`}>
                            <div className={`${Styles.otherRowNetworkItem}`}>
                                <CryptoIcon short_name={'ETH'} mediumSize={true}/>
                                <div className={`${Styles.cryptoName}`}>Ethereum</div>
                            </div>
                            <div className={`${Styles.otherRowNetworkItem}`}>
                                <CryptoIcon short_name={'BSC'} mediumSize={true}/>
                                <div className={`${Styles.cryptoName}`}>BSC</div>
                            </div>
                            <div className={`${Styles.otherRowNetworkItem}`}>
                                <CryptoIcon short_name={'ETH'} mediumSize={true}/>
                                <div className={`${Styles.cryptoName}`}>Ethereum</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Next'} link='/wallet-add' btnType={mainRowStatus || otherRowStatus ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletImportStep2;