
export const api = {
    // host:  process.env.REACT_APP_API_URL,
    // host:  process.env.REACT_APP_API_URL,
    // host:  'https://api.omt.technology/',
    host:  'https://api.wallet.adtdev.pro/',
    authorization: {
        import: 'api/v1/auth/import',
        registration: 'api/v1/register',
        getPhrase: 'api/v1/phrase',
        getConfirmationPhrase: 'api/v1/phrase/confirmation',
        login: 'api/v1/login'
    },
    blockchain: {
        all: 'api/v3/available_blockchains'
    },
    wallet: {
        mainData: 'api/v1/selected-account',
        blockchains: 'api/v1/blockchains',
        wallets: 'api/v1/accounts',
        changeNetworks: 'api/v1/settings/change-network',
        assets: 'api/v1/assets',
        addAssets: 'api/v1/assets/attach',
        removeAssets: 'api/v1/assets/detach',
        tokens: 'api/v1/tokens',
        currencyGroups: 'api/v1/currency-groups',
        assetsHistory: 'api/v1/transactions',
        transactionDetails: 'api/v1/transactions/1/show',
        connect: 'api/v1/one-time-integration-tokens/generate'
    }
}