import React from "react";
import Styles from './styles.module.scss'
import MainPreview from "../../components/mainPreview";
import Authorization from "../../components/authorization";
import CustomRouter from "../../components/customRouter";



const MainPage = () => {
    return (
        <section className={`${Styles.container}`}>
            <MainPreview/>
            <Authorization/>
            <CustomRouter page='main'/>
        </section>
    );
};

export default MainPage;