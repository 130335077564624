import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import CreateWalletForm from "../../components/createWalletForm";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {registrationSuccess} from "../../store/main/action";
import CustomRouter from "../../components/customRouter";



const CreateWalletPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const registration = useSelector(store => store.main.registration);

    useEffect(()=>{
        if (registration){
            navigate('/create-success');
            dispatch(registrationSuccess(false));
        }
    },[dispatch, navigate, registration]);


    return (
        <section className={`${Styles.container}`}>
            <CreateWalletForm/>
            <CustomRouter page='create'/>
        </section>
    );
};

export default CreateWalletPage;