import React, {useState} from "react";
import Styles from './styles.module.scss'
import CustomRouter from "../../components/customRouter";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CustomBtn from "../../components/_UI/customBtn";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {importWallet} from "../../store/main/action";


const ImportWalletPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mnemonic, setMnemonic] = useState('');

    const mainBtnHandler = () =>{
        if (mnemonic.length > 5){
            dispatch(importWallet({backup_phrase: mnemonic}));
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.areaWrap}`}>
                        <textarea
                            placeholder='Paste or enter your mnemonic, private key, or keystore'
                            onChange={(e)=>{setMnemonic(e.target.value)}}
                        />
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Import Wallet'} link={mnemonic.length > 5 ? '/' : false} btnType={mnemonic.length > 5 ? 'wb' : 'db'} action={mainBtnHandler}/>
                </div>
            </div>
            <CustomRouter page='import'/>
        </section>
    );
};

export default ImportWalletPage;
