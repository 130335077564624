import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import searchIcon from '../../theme/assets/svg/omt_search_icon.svg';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addWalletAssets, addWalletAssetsSuccess, getTokens, setMessageModalStatus} from "../../store/wallet/action";
import CryptoIcon from "../../components/_UI/cryptoIcon";
import copyIconActive from "../../theme/assets/svg/omt_copy_icon_active.svg";
import copyIconPassive from "../../theme/assets/svg/omt_copy_icon_passive.svg";
import addIcon from '../../theme/assets/svg/omt_add_icon.svg';





const WalletAddTokenPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const walletTokens = useSelector(store => store.wallet.walletTokens);
    const addWalletAssetsStatus = useSelector(store => store.wallet.addWalletAssets);
    
    
    const [searchData, setSearchData] = useState('');
    const [addressCopied, setAddressCopied] = useState('');

    const copyAddressHandler = (address) =>{
        if (address){
            navigator.clipboard.writeText(address);
            setAddressCopied(address)
        }
    }

    const searchBtnHandler = () =>{
        dispatch(getTokens(searchData));
    }
    
    useEffect(()=>{
        if (addWalletAssetsStatus){
            dispatch(setMessageModalStatus('Success'));
            dispatch(getTokens());
            dispatch(addWalletAssetsSuccess(false))
        }
    },[addWalletAssetsStatus, dispatch])

    useEffect(()=>{
        dispatch(getTokens());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.linkWrap}`}>
                    <div className={`${Styles.contentWrapItem}`} onClick={()=>{navigate('/wallet')}}>
                        <div className={`${Styles.iconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Back</div>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <div className={`${Styles.title4}`} onClick={()=>{}}>Custom token</div>
                    </div>
                </div>
                <div className={`${Styles.searchWrap}`}>
                    <input type="text" placeholder="Token Name / Contract Address / ID" value={searchData} onChange={(e)=>{
                        setSearchData(e.target.value);
                        dispatch(getTokens(e.target.value));
                    }}/>
                    <div className={`${Styles.absSearchBtn}`} onClick={()=>{searchBtnHandler()}}>
                        <img src={searchIcon} alt="search"/>
                    </div>
                </div>
                <div className={`${Styles.tableWrap}`}>
                    {walletTokens?.tokens?.length > 0 ?
                        walletTokens.tokens.map((item, index)=>{
                            return (
                                <div className={`${Styles.tableRow}`} key={'walletTokens' + index}>
                                    <div className={`${Styles.tableRowIconWrap}`}>
                                        <CryptoIcon short_name={item.code} mediumPSize={true}/>
                                    </div>
                                    <div className={`${Styles.tableRowContentWrap}`}>
                                        <div className={`${Styles.tableRowContentTitle}`}>
                                            {item.code} <span>({item.name})</span>
                                        </div>
                                        <div className={`${Styles.tableRowContentAmount}`}>0 {item.code} <span>(0$)</span></div>
                                        <div className={`${Styles.tableRowContentAddress}`}>
                                            <div className={`${Styles.address}`}>{item.contract_address}</div>
                                            <div className={`${Styles.copyBtn}`} onClick={()=>{copyAddressHandler(item.contract_address)}}>
                                                <img src={addressCopied === item.contract_address ? copyIconActive : copyIconPassive} alt="copy address btn"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.addBtn}`} onClick={()=>{dispatch(addWalletAssets(item.id))}}>
                                        <img src={addIcon} alt="add token"/>
                                    </div>
                                </div>
                            )
                        }) :
                        <div className={`${Styles.emptyMsg}`}>{searchData.length > 0 ? 'Tokens not found' : walletTokens ? 'Tokens not found' : 'Loading...'}</div>
                    }
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletAddTokenPage;