import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import CryptoIcon from "../_UI/cryptoIcon";

import dropdownIcon from '../../theme/assets/svg/owt_dropdown_arrow.svg';
import linkIcon from '../../theme/assets/svg/owt_link_icon.svg';
import reloadIcon from '../../theme/assets/svg/owt_reload_icon.svg';
import settingsIcon from '../../theme/assets/svg/owt_settings_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {setCurrentBlockchain, setNetworkModalStatus} from "../../store/wallet/action";
import {useNavigate} from "react-router-dom";


const WalletSettings = (props) => {
    const {reload} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const walletData = useSelector(store => store.wallet.mainWalletData);
    const currentNetworkId = useSelector(store => store.wallet.currentWalletNetworkId);
    
    const [currentNetwork, setCurrentNetwork] = useState( 'Loading...')


    const networkBtnHandler = () =>{
        dispatch(setNetworkModalStatus(true));
    }

    
    useEffect(()=>{
        if (walletData && currentNetworkId){
            walletData?.networks?.forEach((item)=>{
                if (item.id === currentNetworkId){
                    setCurrentNetwork(item.name) 
                }
            })
        }
    },[currentNetworkId, walletData])
    
    useEffect(()=>{
        if (walletData){
            localStorage.setItem('owt-cb_id', walletData?.account?.blockchain_id);
            dispatch(setCurrentBlockchain(walletData?.account?.blockchain_id));
        }
    },[dispatch, walletData])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.blockchainBtn}`} onClick={()=>{navigate('/switch-wallet')}}>
                    {walletData ?
                        <>
                            <CryptoIcon short_name={walletData?.account?.currency_code} smallSize={true}/>
                            <div className={`${Styles.blockchainName}`}>{walletData?.account?.blockchain}</div>
                        </> :
                        <div className={`${Styles.blockchainName}`}>Loading...</div>
                    }

                </div>
                <div className={`${Styles.networkBtn}`} onClick={()=>{networkBtnHandler()}}>
                    <div className={`${Styles.networkName}`}>
                        {walletData ? currentNetwork : 'Loading...'}
                    </div>
                    <div className={`${Styles.dropdownIconWrap}`}>
                        <img src={dropdownIcon} alt="dropdown arrow"/>
                    </div>
                </div>
                <div className={`${Styles.otherBtnsWrap}`}>
                    <div className={`${Styles.btnItem}`}>
                        <img src={linkIcon} alt="link"/>
                    </div>
                    <div className={`${Styles.btnItem}`} onClick={()=>{reload()}}>
                        <img src={reloadIcon} alt="reload"/>
                    </div>
                    <div className={`${Styles.btnItem}`} onClick={()=>{navigate('/wallet-settings')}}>
                        <img src={settingsIcon} alt="settings"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletSettings;
