import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import radioIconActive from '../../theme/assets/svg/omt_radio_icon_active.svg';
import radioIconPassive from '../../theme/assets/svg/omt_radio_icon_passive.svg';
import CryptoIcon from "../../components/_UI/cryptoIcon";
import CustomBtn from "../../components/_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";
import {getCurrencyGroups, setCurrencyGroup} from "../../store/wallet/action";





const WalletGenerateStep1 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currencyGroups = useSelector(store => store.wallet.currencyGroups);
    const currentCurrencyGroup = useSelector(store => store.wallet.currentCurrencyGroup);

    
    useEffect(()=>{
        dispatch(getCurrencyGroups());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet-add')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    {currencyGroups?.length > 0 ?
                        currencyGroups.map((item, index)=>{
                            return (
                                <div className={`${Styles.otherRow}`} key={'currencyGroups' + index}>
                                    <div className={`${Styles.otherRowTitleWrap}`}>
                                        <div className={`${Styles.otherRowTitle}`}>{item.name}</div>
                                        <div
                                            className={`${Styles.radioBtn}`}
                                            onClick={()=>{dispatch(setCurrencyGroup(item.id))}}
                                        >
                                            <img src={item.id === currentCurrencyGroup ? radioIconActive : radioIconPassive} alt="radio checkbox"/>
                                        </div>
                                    </div>
                                    <div className={`${Styles.otherRowNetworkWrap}`}>
                                        {item.currencies.map((itemC, indexC)=>{
                                            return (
                                                <div className={`${Styles.otherRowNetworkItem}`} key={itemC.name + index}>
                                                    <CryptoIcon short_name={itemC.code} mediumSize={true}/>
                                                    <div className={`${Styles.cryptoName}`}>{itemC.name}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }) : null}
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Next'} link='/wallet-generate-2' btnType={currentCurrencyGroup ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletGenerateStep1;