import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import NextBtn from "../../components/_UI/nextBtn";
import privacyIcon from "../../theme/assets/svg/omt_privacy_icon.svg";
import agreementIcon from "../../theme/assets/svg/omt_agreement_icon.svg";
import websiteIcon from '../../theme/assets/svg/omt_website_icon.svg';
import logo from "../../theme/assets/svg/omt_main_logo.svg";





const AboutPage = () => {
    const navigate = useNavigate();

    const [currentHover, setCurrentHover] = useState('');

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet-settings')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>About Us</div>
                </div>
                <div className={`${Styles.versionBox}`}>
                    <div className={`${Styles.logoWrap}`}>
                        <img src={logo} alt="omt logotype"/>
                    </div>
                    <div className={`${Styles.version}`}>Version 1.0.2</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'privacy' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('privacy')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={privacyIcon} alt="Privacy Policy"/>
                                </div>
                                <div className={`${Styles.name}`}>Privacy Policy</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>{''}</div>
                        </div>
                        <NextBtn isHover={currentHover === 'privacy'}/>
                    </div>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'agreement' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('agreement')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={agreementIcon} alt="User Agreement"/>
                                </div>
                                <div className={`${Styles.name}`}>User Agreement</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>{''}</div>
                        </div>
                        <NextBtn isHover={currentHover === 'agreement'}/>
                    </div>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'website' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('website')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={websiteIcon} alt="Website"/>
                                </div>
                                <div className={`${Styles.name}`}>Website</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>{''}</div>
                        </div>
                        <NextBtn isHover={currentHover === 'website'}/>
                    </div>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default AboutPage;