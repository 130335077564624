import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'

import listArrowIcon from '../../theme/assets/svg/omt_list_arrow_icon.svg';
import linkIcon from '../../theme/assets/svg/omt_link_icon.svg';
import CustomBtn from "../../components/_UI/customBtn";
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAgreementStatus} from "../../store/main/action";



const UserAgreementPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const agreementStatus = useSelector(store => store.main.agreementStatus);

    const [mainBtnStatus, setMainBtnStatus] = useState(false);

    const onScroll = (e) =>{
        if (e.target.scrollTop === (e.target.scrollHeight - e.target.clientHeight)){
            setMainBtnStatus(true)
        }
    }
    
    const mainBtnHandler = () =>{
        if (mainBtnStatus){
            navigate('/create');
            localStorage.setItem('owt-as', 'ok');
            dispatch(setAgreementStatus(true));
        }
    }
    
    useEffect(()=>{
        if (agreementStatus){
            navigate('/create');
        }
    },[agreementStatus, navigate])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.agreementWrap}`}>
                    <div className={`${Styles.agreementTitleRow}`}>
                        <div className={`${Styles.backIconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title5}`}>User Agreement</div>
                        <div className={`${Styles.linkIconWrap}`}>
                            <img src={linkIcon} alt="link icon"/>
                        </div>
                    </div>
                    <div className={`${Styles.agreementInfoRow}`}>
                        <div className={`${Styles.title6}`}>OMT Wallet User Terms and Privacy Policy</div>
                        <div className={`${Styles.title6}`}>Updated on March 08, 2023</div>
                    </div>
                    <div className={`${Styles.agreementContentWrap}`}>
                        <div className={`${Styles.agreementContent}`} onScroll={onScroll}>
                            Dear users:<br/>
                            Thank you for choosing TronLink. TronLink (hereinafter referred to as "the Service",
                            "Service", "we" or "Platform") is a digital asset management service and a trading
                            platform of digital assets. All natural persons or entities that log in to this product
                            count towards users of this product (hereinafter referred to as "you" or "user").
                            For users' convenience, this Agreement may come in multiple languages. Should conflicts
                            or omissions happen, the Chinese version will prevail. We recommend that you read and
                            understand the policies in their entirety before using this Product ("TronLink"),
                            and important information, including a digital asset management service and a trading
                            platform of digital assets. All natural persons or entities that log in to this product
                            count towards users of this product (hereinafter referred to as "you" or "user").
                            For users' convenience, this Agreement may come in multiple languages. Should conflicts
                            or omissions happen, the Chinese version will prevail.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn action={mainBtnHandler} text={mainBtnStatus ? 'I agree no more reminding' : 'Scroll down to read all the terms'} link={mainBtnStatus ? '/create' : null} btnType={mainBtnStatus ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='user-agreement'/>
        </section>
    );
};

export default UserAgreementPage;