import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    changeNetwork,
    setCurrentWalletNetworkId,
    setNetworkModalStatus
} from "../../store/wallet/action";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";

import checkIconActive from '../../theme/assets/svg/omt_check_icon_active.svg';
import checkIconPassive from '../../theme/assets/svg/omt_check_icon_pasive.svg';



const WalletNetworkModal = () => {
    const dispatch = useDispatch();
    const walletData = useSelector(store => store.wallet.mainWalletData);
    const modalStatus = useSelector(store => store.wallet.networkModalStatus);
    const currentNetworkId = useSelector(store => store.wallet.currentWalletNetworkId);


    const changeNetworkHandler = (id) =>{
        dispatch(changeNetwork(id));
        dispatch(setCurrentWalletNetworkId(id));
        dispatch(setNetworkModalStatus(false));
    }

    const closeModal = () =>{
        dispatch(setNetworkModalStatus(false));
    }


    return (
        <Popup
            open={modalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalTitleRow}`}>
                    <div className={`${Styles.titleWrap}`}>
                        <div className={`${Styles.backIconWrap}`} onClick={()=>{closeModal()}}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Networks</div>
                    </div>
                    <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                        <div/>
                        <div/>
                    </div>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalNetworkWrap}`}>
                        {walletData ? walletData?.networks?.map((item, index)=>{
                            return (
                                <div className={`${Styles.modalNetworkItem}`} key={'networkItem' + index} onClick={()=>{changeNetworkHandler(item.id)}}>
                                    <div className={`${Styles.modalNetworkItemName} ${item.id === currentNetworkId ? Styles.modalNetworkItemNameActive : null}`}>{item.name}</div>
                                    <div className={`${Styles.modalNetworkItemCheck}`}><img src={item.id === currentNetworkId ? checkIconActive : checkIconPassive} alt="checkbox"/></div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default WalletNetworkModal;
