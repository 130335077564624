import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setSendModalStatus} from "../../store/wallet/action";

import successIcon from '../../theme/assets/svg/omt_modal_success_icon.svg';
import CustomBtn from "../_UI/customBtn";
import {useNavigate} from "react-router-dom";




const SendModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modalStatus = useSelector(store => store.wallet.sendModalStatus);


    const closeModal = () =>{
        dispatch(setSendModalStatus({status: false, hash: false}));
    }

    const mainBtnHandler = (type) =>{
        if (type === 'wallet'){
            navigate('/wallet');
            closeModal();
        } else {
            navigate(`/transaction/details/${modalStatus.hash}`);
            closeModal();
        }
    }


    return (
        <Popup
            open={modalStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className='typeMessage'
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.textWrap}`}>
                    <div className={`${Styles.posWrap}`}>
                        <div className={`${Styles.textImgWrap}`}>
                            <img src={successIcon} alt={modalStatus ? modalStatus : ''}/>
                        </div>
                        <div className={`${Styles.text}`}>Success!</div>
                    </div>
                    <div className={`${Styles.btnsWrap}`}>
                        <CustomBtn text={'Wallet'} link={false} btnType={'wb'} action={mainBtnHandler} actionParams={'wallet'}/>
                        <CustomBtn text={'Details'} link={false} btnType={'wb'} action={mainBtnHandler} actionParams={'details'}/>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default SendModal;
