import React from "react";
import Styles from './styles.module.scss'
import logo from '../../theme/assets/svg/omt_main_logo.svg';


const MainPreview = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.logoWrap}`}>
                    <img src={logo} alt="omt logotype"/>
                </div>
                <div className={`${Styles.title1}`}>Start your <span>Web3.0</span></div>
                <div className={`${Styles.note}`}>
                    TronLink is a decentralized wallet that prioritizes security to
                    safeguard your transactions while you enjoy your Web3 journey.
                </div>
            </div>
        </section>
    );
};

export default MainPreview;
