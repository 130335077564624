import React from "react";
import Styles from './styles.module.scss'


import successIcon from '../../theme/assets/svg/omt_success_icon.svg';
import CustomBtn from "../../components/_UI/customBtn";
import CustomRouter from "../../components/customRouter";



const SeedSuccessPage = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.statusWrap}`}>
                        <div className={`${Styles.statusImgWrap}`}>
                            <img src={successIcon} alt="success"/>
                        </div>
                        <div className={`${Styles.statusName}`}>Successfull</div>
                    </div>
                    <div className={`${Styles.titleWrap}`}>
                        <div className={`${Styles.titleNote}`}>
                            You have successfully passed <br/> the test of your seed prhase!
                        </div>
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Let’s go to wallet!'} link='/wallet' btnType={'wb'}/>
                </div>
            </div>
            <CustomRouter page='seed-success'/>
        </section>
    );
};

export default SeedSuccessPage;