import React from "react";
import Styles from './styles.module.scss';



const NextBtn = (props) => {
    const {isHover} = props;

    return (
        <div className={`${Styles.iconWrap}`}>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 15L7.29289 8.70711C7.68342 8.31658 7.68342 7.68342 7.29289 7.29289L1 0.999999"
                    stroke={isHover ? "#F7931A" : "#F5F5F5"}
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

export default NextBtn;
