import React, {useEffect, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAuthorizationStatus, setPhraseStatus} from "../../store/main/action";


const CustomRouter = (props) => {
    const {page} = props;
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const token = useSelector(store => store.main.authorizationStatus);
    const uuid = useSelector(store => store.main.uuidStatus);
    const phrase = useSelector(store => store.main.phraseStatus);

    const navigateHandler = useCallback((authorizationWithoutPhraseLink, authorizationWithoutTokenLink, authorizationSuccessLink, firstPersonLink) =>{
        if (token && uuid && phrase){
            if (authorizationSuccessLink){
                navigate(authorizationSuccessLink);
            }
        } else if (token && uuid){
            if (authorizationWithoutPhraseLink){
                navigate(authorizationWithoutPhraseLink);
            }
        } else if (uuid && phrase){
            if (authorizationWithoutTokenLink){
                navigate(authorizationWithoutTokenLink);
            }
        } else if (!uuid){
            if (firstPersonLink){
                localStorage.removeItem('owt-ps');
                localStorage.removeItem('owt');
                navigate(firstPersonLink);
                dispatch(setAuthorizationStatus(false));
                dispatch(setPhraseStatus(false));
            }
        }
    },[dispatch, navigate, phrase, token, uuid])


    const pageHandler = useCallback(() =>{
        switch (page){
            case 'main':
                navigateHandler('/seed-info', '/sign-in', '/wallet', null);
                break;
            case 'user-agreement':
                navigateHandler('/seed-info', '/sign-in', '/wallet', null);
                break;
            case 'create':
                navigateHandler('/create-success', '/sign-in', '/wallet', null);
                break;
            case 'import':
                navigateHandler('/seed-info', '/sign-in', '/wallet', null);
                break;
            case 'create-success':
                navigateHandler(null, '/sign-in', '/wallet', null);
                break;
            case 'sign-in':
                navigateHandler('/seed-info', null, '/wallet', '/');
                break;
            case 'seed-info':
                navigateHandler(null, '/sign-in', '/wallet', '/');
                break;
            case 'seed-create':
                navigateHandler(null, '/sign-in', '/wallet', '/');
                break;
            case 'seed-confirm':
                navigateHandler(null, '/sign-in', '/seed-success', '/');
                break;
            case 'seed-success':
                navigateHandler(null, '/sign-in', null, '/');
                break;
            case 'wallet':
                navigateHandler('/seed-info', '/sign-in', null, '/');
                break;
            default:
                return null
        }
    }, [navigateHandler, page])


    
    useEffect(()=>{
        pageHandler()
    },[pageHandler])

    return <></>
};

export default CustomRouter;
