import React, {useState} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";

import checkIconP from '../../theme/assets/svg/omt_check_icon2P.svg';
import checkIconA from '../../theme/assets/svg/omt_check_icon2A.svg';
import CustomBtn from "../../components/_UI/customBtn";
import CustomRouter from "../../components/customRouter";




const SeedInfoPage = () => {

    const [checkStatus, setCheckStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.backIconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Seed phrase</div>
                    </div>
                    <div className={`${Styles.titleNote}`}>
                        <div className={`${Styles.titleNoteItem}`}>Important information:</div>
                        <div className={`${Styles.titleNoteItem}`}>Your secret recovery phrase makes it easy to back up and restore your account</div>
                    </div>
                    <div className={`${Styles.listWrap}`}>
                        <div className={`${Styles.listItem}`}>
                            <div className={`${Styles.listItemMark}`}>.</div>
                            <div className={`${Styles.listItemContent}`}>Never reveal your secret recovery phrase;</div>
                        </div>
                        <div className={`${Styles.listItem}`}>
                            <div className={`${Styles.listItemMark}`}>.</div>
                            <div className={`${Styles.listItemContent}`}>Anyone who knows this phrase can take your funds and account forever;</div>
                        </div>
                        <div className={`${Styles.listItem}`}>
                            <div className={`${Styles.listItemMark}`}>.</div>
                            <div className={`${Styles.listItemContent}`}>If you lose your secret phrase, you may lose access to your account forever, so memorize the secret phrase and write it down on multiple devices or paper, or you can download the recovery phrase and store it in a safe place on an external encrypted hard drive or media.</div>
                        </div>
                    </div>
                    <div className={`${Styles.checkWrap}`}>
                        <div className={`${Styles.checkBtn}`} onClick={()=>{setCheckStatus(!checkStatus)}}>
                            <img src={checkStatus ? checkIconA : checkIconP} alt="checkbox"/>
                        </div>
                        <div className={`${Styles.checkNote}`}>I confirm that I have read all the warnings</div>
                    </div>
                    <div className={`${Styles.noteWrap}`}>Once you click on the <span>"Generate"</span> button you will see your secret phrase, please memorize and copy it to your device, in the next window you will need to repeat it.</div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Generate'} link='/seed-create' btnType={checkStatus ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='seed-info'/>
        </section>
    );
};

export default SeedInfoPage;