import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CustomBtn from "../../components/_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";
import {getConnectCode, getConnectCodeSuccess} from "../../store/wallet/action";

import copyIconActive from "../../theme/assets/svg/omt_copy_icon_active.svg";
import copyIconPassive from "../../theme/assets/svg/omt_copy_icon_passive.svg";





const WalletConnectPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const connectCodeStatus = useSelector(store => store.wallet.connectCodeStatus);

    const [copyCodeStatus, setCopyCodeStatus] = useState(false);

    const generateHandler = () =>{
        dispatch(getConnectCode());
    }

    const copyCodeHandler = () =>{
        if (connectCodeStatus){
            navigator.clipboard.writeText(connectCodeStatus.token.token);
            setCopyCodeStatus(true);
        }
    }


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div
                    className={`${Styles.backBtnRow}`}
                    onClick={()=>{
                        navigate('/wallet-settings');
                        dispatch(getConnectCodeSuccess(false));
                    }}
                >
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Connect</div>
                </div>
                <div className={`${Styles.note}`}>
                    To connect your wallet to Honee 2.0, you need to get a personal connection code.
                    Click the "Generate" button and follow the further instructions.
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <CustomBtn text={'Generate'} link='' btnType={'wb'} action={generateHandler}/>
                </div>
                {connectCodeStatus?.token?.token ?
                    <div className={`${Styles.fakeInputWrap}`}>
                        <div className={`${Styles.fakeInputTitle}`}>Your connection code:</div>
                        <div className={`${Styles.fakeInputRow}`}>
                            <div className={`${Styles.fakeInput}`}>{connectCodeStatus.token.token}</div>
                            <div className={`${Styles.copyBtn}`} onClick={()=>{copyCodeHandler()}}>
                                <img src={copyCodeStatus ? copyIconActive : copyIconPassive} alt="copy address btn"/>
                            </div>
                        </div>
                        <div className={`${Styles.fakeInputNote}`}>
                            You can use this connection code to login and register on Honee 2.0. <br/>
                            Copy your code and go to <span>Honee 2.0</span>.
                        </div>
                    </div> : null
                }
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletConnectPage;