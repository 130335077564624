import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import {useDispatch} from "react-redux";
import {getBlockchain, getMainWalletData} from "../../store/wallet/action";
import CustomRouter from "../../components/customRouter";
import WalletSwitchHeader from "../../components/walletSwitchHeader";
import WalletSwitchBlockchains from "../../components/walletSwitchBlockchains";
import WalletSwitchAccounts from "../../components/walletSwitchAccounts";



const WalletSwitchPage = () => {
    const dispatch = useDispatch();

    const [network, setNetwork] = useState(false);

    useEffect(()=>{
        dispatch(getMainWalletData());
        dispatch(getBlockchain());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <WalletSwitchHeader/>
            <WalletSwitchBlockchains network={network} setNetwork={setNetwork}/>
            <WalletSwitchAccounts network={network}/>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletSwitchPage;