import React, {useState} from "react";
import QRCode from "react-qr-code";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import copyIconActive from "../../theme/assets/svg/omt_copy_icon_active.svg";
import copyIconPassive from "../../theme/assets/svg/omt_copy_icon_passive.svg";
import {useNavigate} from "react-router-dom";


const WalletReceiveContent = (props) => {
    const {address, blockchain} = props;
    const navigate = useNavigate();

    const [copyAddressStatus, setCopyAddressStatus] = useState(false);


    const copyAddressHandler = () =>{
        if (address){
            navigator.clipboard.writeText(address);
            setCopyAddressStatus(true)
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.mainNote}`}>Only <span>{blockchain}</span> network assets are supported</div>
                    <div className={`${Styles.qrWrap}`}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "19rem", width: "19rem" }}
                            value={address}
                            viewBox={`0 0 256 256`}
                            fgColor={'#ed8d19'}
                            bgColor={'#211c16'}
                        />
                    </div>
                    <div className={`${Styles.fakeInput}`}>
                        <div className={`${Styles.address}`}>{address}</div>
                        <div className={`${Styles.copyBtn}`} onClick={()=>{copyAddressHandler()}}>
                            <img src={copyAddressStatus ? copyIconActive : copyIconPassive} alt="copy address btn"/>
                        </div>
                    </div>
                    <div className={`${Styles.note}`}>
                        * You can transfer only TRON-based tokens (e.g. TRX or TRC10/20/721 tokens)
                        to this account. Other tokens may get lost during transfer.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletReceiveContent;
