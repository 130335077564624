import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import CustomBtn from "../../components/_UI/customBtn";
import CustomInput from "../../components/_UI/customInput";
import {
    createNewAccount, createNewAccountFailure,
    createNewAccountSuccess,
    setCurrencyGroup,
    setMessageModalStatus
} from "../../store/wallet/action";





const WalletGenerateStep2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentCurrencyGroup = useSelector(store => store.wallet.currentCurrencyGroup);
    const createNewAccountStatus = useSelector(store => store.wallet.createNewAccountStatus);
    const createNewAccountStatusErr = useSelector(store => store.wallet.createNewAccountStatusErr);
    

    const [wallet_name, setWallet_name] = useState('');

    const mainBtnHandler = () =>{
        if (wallet_name.length > 1 && wallet_name.length < 11){
            dispatch(createNewAccount({currency_group: currentCurrencyGroup, wallet_name}))
        }
    }

    useEffect(()=>{
        if (!currentCurrencyGroup){
            navigate('/wallet-generate-1')
        }
    },[currentCurrencyGroup, dispatch, navigate])
    
    useEffect(()=>{
        if (createNewAccountStatus){
            dispatch(setMessageModalStatus('Success'));
            navigate('/wallet');
            dispatch(createNewAccountSuccess(false));
            dispatch(setCurrencyGroup(false));
        }
    },[createNewAccountStatus, dispatch, navigate])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet-generate-1')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.note}`}>
                        Create one more sub account on current HD wallet
                    </div>
                    <CustomInput
                        type='text'
                        title='Account Name (2-10 characters)'
                        // note='2-10 characters'
                        placeholder='Your Account Name'
                        value={wallet_name}
                        setValue={setWallet_name}
                        standardErrData={{message: createNewAccountStatusErr, action: createNewAccountFailure, name: 'wallet_name'}}
                    />
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Create Account'} link='/wallet-add' btnType={wallet_name.length > 1 && wallet_name.length < 11 ? 'wb' : 'db'} action={mainBtnHandler}/>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletGenerateStep2;