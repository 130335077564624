import React, {useEffect} from "react";
import Styles from './styles.module.scss';

import CustomRouter from "../../components/customRouter";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getCurrentWalletAssets, getWalletAssetsFee} from "../../store/wallet/action";
import WalletSendConfirmationContent from "../../components/walletSendConfirmationContent";
import SendModal from "../../components/sendModal";



const WalletSendConfirmationPage = () => {
    const {id, address, amount} = useParams();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if (id){
            dispatch(getCurrentWalletAssets(id));
            if (address && amount){
                dispatch(getWalletAssetsFee(id, {wallet: address, amount}));
            }
        }
    },[address, amount, dispatch, id])

    return (
        <section className={`${Styles.container}`}>
            <WalletSendConfirmationContent/>
            <CustomRouter page='wallet'/>
            <SendModal/>
        </section>
    );
};

export default WalletSendConfirmationPage;