import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from './translationEN.json';
import translationRU from './translationRU.json';
import translationDE from './translationDE.json';
import translationES from './translationES.json';
import translationZH from './translationZH.json';

const lsLng = localStorage.getItem('omtLng');

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
    de: {
        translation: translationDE
    },
    es: {
        translation: translationES
    },
    zh: {
        translation: translationZH
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lsLng ? lsLng : "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
