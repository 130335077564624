import React, {useState} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";

import swapIcon from '../../theme/assets/svg/omt_swap_icon.svg';
import sendIcon from '../../theme/assets/svg/omt_action_send_icon.svg';
import receiveIcon from '../../theme/assets/svg/omt_action_receive_icon.svg';
import balanceShowIconActive from '../../theme/assets/svg/omt_balance_show_icon_active.svg';
import balanceShowIconPassive from '../../theme/assets/svg/omt_balance_show_icon_pasive.svg';
import copyIconPassive from '../../theme/assets/svg/omt_copy_icon_passive.svg';
import copyIconActive from '../../theme/assets/svg/omt_copy_icon_active.svg';
import {useNavigate} from "react-router-dom";



const WalletMain = () => {
    const navigate = useNavigate();

    const walletData = useSelector(store => store.wallet.mainWalletData);

    const [showBalanceStatus, setShowBalanceStatus] = useState(JSON.parse(localStorage.getItem('omt-sbs')));
    const [copyAddressStatus, setCopyAddressStatus] = useState(false);

    const showBalanceHandler = () =>{
        if (showBalanceStatus){
            localStorage.setItem('omt-sbs', 'false');
            setShowBalanceStatus(false);
        } else {
            localStorage.setItem('omt-sbs', 'true');
            setShowBalanceStatus(true);
        }
    }

    const copyAddressHandler = () =>{
        if (walletData){
            navigator.clipboard.writeText(walletData?.account?.address);
            setCopyAddressStatus(true)
        }
    }


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.walletWrap}`}>
                    <div className={`${Styles.nameRow}`}>
                        <div className={`${Styles.name}`}>{walletData ? walletData?.account?.wallet_name : 'Loading...'}</div>
                        <div className={`${Styles.swapBtn}`} onClick={()=>{navigate('/switch-wallet')}}>
                            <img src={swapIcon} alt="swap wallet"/>
                        </div>
                        {walletData?.account?.blockchain === 'Tron' ?
                            <div className={`${Styles.bandwidth}`}>Bandwidth: <span>{walletData?.account?.bandwidth_total - walletData?.account?.bandwidth_used}/{walletData?.account?.bandwidth_total}</span></div>
                            : null}
                    </div>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceBtn}`} onClick={()=>{showBalanceHandler()}}>
                            <img src={showBalanceStatus ? balanceShowIconPassive : balanceShowIconActive} alt="show hide balance"/>
                        </div>
                        <div className={`${Styles.balance}`} style={{fontSize: `${showBalanceStatus ? '2' : '2.8'}rem`}}>{showBalanceStatus ? `${walletData ? walletData?.asset?.amount * 1 : '0'} ${walletData ? walletData?.account?.currency_code : ''}` : '****'}</div>
                    </div>
                    <div className={`${Styles.addressRow}`}>
                        <div className={`${Styles.title6}`}>Wallet address:</div>
                        <div className={`${Styles.addressBox}`}>
                            <div className={`${Styles.address}`}>{walletData ? walletData?.account?.address : 'Loading...'}</div>
                            <div className={`${Styles.copyBtn}`} onClick={()=>{copyAddressHandler()}}>
                                <img src={copyAddressStatus ? copyIconActive : copyIconPassive} alt="copy address btn"/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={`${Styles.actionsWrap}`}>
                    <div className={`${Styles.actionsItem}`} onClick={()=>{navigate(`/send/${walletData?.asset?.id}`)}}>
                        <div className={`${Styles.actionsItemImgWrap}`}>
                            <img src={sendIcon} alt="Send"/>
                        </div>
                        <div className={`${Styles.actionsItemName}`}>Send</div>
                    </div>
                    <div className={`${Styles.actionsItem}`} onClick={()=>{navigate(`/receive/${walletData?.account?.address}/${walletData?.account?.blockchain}`)}}>
                        <div className={`${Styles.actionsItemImgWrap}`}>
                            <img src={receiveIcon} alt="Receive"/>
                        </div>
                        <div className={`${Styles.actionsItemName}`}>Receive</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletMain;
