import React, {useCallback, useEffect, useState} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import CustomInput from "../_UI/customInput";
import CustomBtn from "../_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";
import {
    getValidateAddressStatus, getValidateAddressStatusFailure, getValidateAddressStatusSuccess,
    sendCryptoFailure
} from "../../store/wallet/action";


const WalletSendContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();

    const currentAsset = useSelector(store => store.wallet.currentWalletAssets);
    const sendErr = useSelector(store => store.wallet.sendCryptoStatusErr);
    const validateErr = useSelector(store => store.wallet.validateAddressStatusErr);
    const validateStatus = useSelector(store => store.wallet.validateAddressStatus);

    console.log(validateStatus)

    
    
    const [amount, setAmount] = useState('');
    const [address, setAddress] = useState('');



    const amountInspectHandler = () =>{
        if (amount !== '' && currentAsset){
            return currentAsset.amount * 1 >= Number(amount)
        } else {return false}
    }

    const addressInspectHandler = () =>{
        return address && address.length > 5
    }

    const sendReadyHandler = () =>{
        if (currentAsset){
            return addressInspectHandler() && amountInspectHandler();
        } else {
            return false
        }
    }

    const mainBtnHandler = () =>{
        if (sendReadyHandler()){
            dispatch(getValidateAddressStatus(id, address));
            // dispatch(sendCrypto(id, {amount, address}))
        }
    }
    
    const clearHandler = useCallback(()=>{
        setAmount('');
        setAddress('');
        dispatch(getValidateAddressStatusSuccess(false));
    },[dispatch])

    useEffect(()=>{
        if (validateStatus?.is_valid){
            navigate(`/send-confirmation/${id}/${address}/${amount}`);
            clearHandler();
        }
    }, [address, amount, clearHandler, id, navigate, validateStatus])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.fakeInputWrap}`}>
                        <div className={`${Styles.itemTitle}`}>Your address {currentAsset?.currency_code ? `(${currentAsset.currency_code})` : ''}</div>
                        <div className={`${Styles.fakeInput}`}>
                            <div className={`${Styles.address}`}>{currentAsset?.address ? currentAsset.address : 'Loading...'}</div>
                        </div>
                    </div>
                    <CustomInput
                        type='text'
                        title='Receiving address'
                        placeholder='Enter the transfer address'
                        value={address}
                        setValue={setAddress}
                        standardErrData={{message: validateErr, action: getValidateAddressStatusFailure, name: 'address'}}
                    />
                    <CustomInput
                        type='number'
                        title='Amount'
                        placeholder='Enter the transfer amount'
                        bottomNote={currentAsset ? [`Available: ${currentAsset.amount * 1} ${currentAsset.currency_code}`] : null}
                        value={amount}
                        setValue={setAmount}
                        standardErrData={{message: sendErr, action: sendCryptoFailure, name: 'amount'}}
                    />
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <CustomBtn text={'Send'} link={false} btnType={sendReadyHandler() ? 'wb' : 'db'} action={mainBtnHandler}/>
                </div>
            </div>
        </section>
    );
};

export default WalletSendContent;
