import React from "react";
import Styles from './styles.module.scss'


import successIcon from '../../theme/assets/svg/omt_success_icon.svg';
import CustomBtn from "../../components/_UI/customBtn";
import CustomRouter from "../../components/customRouter";



const CreateWalletSuccessPage = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.statusWrap}`}>
                        <div className={`${Styles.statusImgWrap}`}>
                            <img src={successIcon} alt="success"/>
                        </div>
                        <div className={`${Styles.statusName}`}>Successfull</div>
                    </div>
                    <div className={`${Styles.titleWrap}`}>
                        <div className={`${Styles.title4}`}>Account are created!</div>
                        <div className={`${Styles.titleNote}`}>
                            Let's get to the secret phrase so you can recover.
                        </div>
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Seed phrase'} link='/seed-info' btnType={'wb'}/>
                </div>
            </div>
            <CustomRouter page='create-success'/>
        </section>
    );
};

export default CreateWalletSuccessPage;