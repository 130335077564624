import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import generateIcon from '../../theme/assets/svg/omt_generate_icon.svg';
import importIcon from '../../theme/assets/svg/omt_import_icon.svg';
import {useNavigate} from "react-router-dom";
import NextBtn from "../../components/_UI/nextBtn";




const WalletAddPage = () => {
    const navigate = useNavigate();

    const [generateHoverStatus, setGenerateHoverStatus] = useState(false);
    const [importHoverStatus, setImportHoverStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/switch-wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div
                        className={`${Styles.contentBoxItem} ${generateHoverStatus ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setGenerateHoverStatus(true)}}
                        onMouseLeave={()=>{setGenerateHoverStatus(false)}}
                        onClick={()=>{navigate('/wallet-generate-1')}}
                    >
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={generateIcon} alt="Generate Sub Account"/>
                        </div>
                        <div className={`${Styles.contentBoxItemTextWrap}`}>
                            <div className={`${Styles.title5}`}>Generate Sub Account</div>
                            <div className={`${Styles.text}`}>Generate related accounts with your HD wallet</div>
                        </div>
                        <NextBtn isHover={generateHoverStatus}/>
                    </div>
                    <div
                        className={`${Styles.contentBoxItem} ${importHoverStatus ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setImportHoverStatus(true)}}
                        onMouseLeave={()=>{setImportHoverStatus(false)}}
                        onClick={()=>{navigate('/wallet-import-1')}}
                    >
                        <div className={`${Styles.contentBoxItemImgWrap}`}>
                            <img src={importIcon} alt="Import Wallet"/>
                        </div>
                        <div className={`${Styles.contentBoxItemTextWrap}`}>
                            <div className={`${Styles.title5}`}>Import Wallet</div>
                            <div className={`${Styles.text}`}>Import the mnemonic, private key, keystore</div>
                        </div>
                        <NextBtn isHover={importHoverStatus}/>
                    </div>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletAddPage;