import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {changeAccountStatus, getMainWalletData} from "../../store/wallet/action";
import {useNavigate} from "react-router-dom";
// import copyIconActive from "../../theme/assets/svg/omt_copy_icon_active.svg";
// import copyIconPassive from "../../theme/assets/svg/omt_copy_icon_passive.svg";



const WalletSwitchAccounts = (props) => {
    const {network} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allWallets = useSelector(store => store.wallet.allWallets);
    const walletData = useSelector(store => store.wallet.mainWalletData);


    const walletHandler = (id)=>{
        dispatch(changeAccountStatus(true));
        dispatch(getMainWalletData(id, true));
        navigate('/wallet');
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.network}`}>{network?.name ? network.name : 'Loading...'}</div>
                <div className={`${Styles.contentBox}`}>
                    {allWallets ? allWallets?.accounts?.map((item, index)=>{
                        return (
                            <div
                                className={`${Styles.contentBoxItemBW} ${walletData?.account?.id === item.id ? Styles.contentBoxItemBWActive : null}`}
                                key={'wallet' + index}
                                onClick={()=>{walletHandler(item.id)}}
                            >
                                <div className={`${Styles.contentBoxItemShadow}`}/>
                                <div className={`${Styles.contentBoxItem}`} key={'wallet' + index}>
                                    <div className={`${Styles.nameRow}`}>
                                        <div className={`${Styles.name}`}>{item.wallet_name}</div>
                                    </div>
                                    <div className={`${Styles.balanceRow}`}>
                                        <div className={`${Styles.balance}`}>{item.amount * 1} {item.currency_code}</div>
                                    </div>
                                    <div className={`${Styles.addressRow}`}>
                                        <div className={`${Styles.title6}`}>Wallet address:</div>
                                        <div className={`${Styles.addressBox}`}>
                                            <div className={`${Styles.address}`}>{item.address}</div>
                                            {/*<div className={`${Styles.copyBtn}`} onClick={()=>{copyAddressHandler(item.address)}}>*/}
                                            {/*    <img src={copyAddressStatus ? copyIconActive : copyIconPassive} alt="copy address btn"/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </section>
    );
};

export default WalletSwitchAccounts;
