import React from "react";
import Styles from './styles.module.scss'
import {Route, Routes, Outlet} from "react-router-dom";
import MainPage from "../../pages/mainPage";
import WalletPage from "../../pages/walletPage";
import CreateWalletPage from "../../pages/createWalletPage";
import ImportWalletPage from "../../pages/importWalletPage";
import UserAgreementPage from "../../pages/userAgreementPage";
import CreateWalletSuccessPage from "../../pages/createWalletSuccessPage";
import SeedInfoPage from "../../pages/seedInfoPage";
import SeedCreatePage from "../../pages/seedCreatePage";
import SeedConfirmPage from "../../pages/seedConfirmPage";
import LoginPage from "../../pages/loginPage";
import SeedSuccessPage from "../../pages/seedSuccessPage";
import WalletSwitchPage from "../../pages/walletSwitchPage";
import WalletReceivePage from "../../pages/walletReceivePage";
import WalletSendPage from "../../pages/walletSendPage";
import WalletAddPage from "../../pages/walletAddPage";
import WalletGenerateStep1 from "../../pages/walletGenerateStep1";
import WalletImportStep1 from "../../pages/walletImportStep1";
import WalletImportStep2 from "../../pages/walletImportStep2";
import MessageModal from "../messageModal";
import WalletAddTokenPage from "../../pages/walletAddTokenPage";
import WalletCurrentAssetsPage from "../../pages/walletCurrentAssetsPage";
import WalletGenerateStep2 from "../../pages/walletGenerateStep2";
import WalletSettingsPage from "../../pages/walletSettingsPage";
import AboutPage from "../../pages/aboutPage";
import WalletSendConfirmationPage from "../../pages/walletSendConfirmationPage";
import TransactionDetailsPage from "../../pages/transactionDetailsPage";
import WalletConnectPage from "../../pages/walletConnectPage";


const AuthenticationLayout = () => (
    <div className={`${Styles.authentication}`}>
        <Outlet />
    </div>
);

const WalletLayout = () => (
    <div className={`${Styles.wallet}`}>
        <Outlet />
        <MessageModal/>
    </div>
);

const MainLayout = () => (
    <div className={`${Styles.main}`}>
        <Outlet />
    </div>
);





const App = () => {
    return (
        <section>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route exact path='/' element={<MainPage/>}/>
                </Route>
                <Route element={<AuthenticationLayout/>}>
                    <Route exact path='/create' element={<CreateWalletPage/>}/>
                    <Route exact path='/sign-in' element={<LoginPage/>}/>
                    <Route exact path='/create-success' element={<CreateWalletSuccessPage/>}/>
                    <Route exact path='/import' element={<ImportWalletPage/>}/>
                    <Route exact path='/user-agreement' element={<UserAgreementPage/>}/>
                    <Route exact path='/seed-info' element={<SeedInfoPage/>}/>
                    <Route exact path='/seed-create' element={<SeedCreatePage/>}/>
                    <Route exact path='/seed-confirm' element={<SeedConfirmPage/>}/>
                    <Route exact path='/seed-success' element={<SeedSuccessPage/>}/>
                </Route>
                <Route element={<WalletLayout/>}>
                    <Route exact path='/wallet' element={<WalletPage/>}/>
                    <Route exact path='/wallet-add' element={<WalletAddPage/>}/>
                    <Route exact path='/wallet-settings' element={<WalletSettingsPage/>}/>
                    <Route exact path='/wallet-add-token' element={<WalletAddTokenPage/>}/>
                    <Route exact path='/switch-wallet' element={<WalletSwitchPage/>}/>
                    <Route exact path='/receive/:address/:blockchain' element={<WalletReceivePage/>}/>
                    <Route exact path='/send/:id' element={<WalletSendPage/>}/>
                    <Route exact path='/transaction/details/:hash' element={<TransactionDetailsPage/>}/>
                    <Route exact path='/send-confirmation/:id/:address/:amount' element={<WalletSendConfirmationPage/>}/>
                    <Route exact path='/wallet-assets/:id' element={<WalletCurrentAssetsPage/>}/>
                    <Route exact path='/wallet-generate-1' element={<WalletGenerateStep1/>}/>
                    <Route exact path='/wallet-generate-2' element={<WalletGenerateStep2/>}/>
                    <Route exact path='/wallet-import-1' element={<WalletImportStep1/>}/>
                    <Route exact path='/wallet-import-2' element={<WalletImportStep2/>}/>
                    <Route exact path='/about' element={<AboutPage/>}/>
                    <Route exact path='/wallet-connect' element={<WalletConnectPage/>}/>
                </Route>
            </Routes>
        </section>
    );
};

export default App;