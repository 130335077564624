import {
    TEST,
    SET_PHRASE_STARTED,
    SET_PHRASE_SUCCESS,
    SET_PHRASE_FAILURE,
    SET_AUTHORIZATION_STATUS,
    REGISTRATION_STARTED,
    REGISTRATION_SUCCESS,
    REGISTRATION_FAILURE,
    GET_PHRASE_STARTED,
    GET_PHRASE_SUCCESS,
    GET_PHRASE_FAILURE,
    GET_CONFIRMATION_PHRASE_STARTED,
    GET_CONFIRMATION_PHRASE_SUCCESS,
    GET_CONFIRMATION_PHRASE_FAILURE,
    SET_PHRASE_STATUS,
    SET_CONFIRMATION_PHRASE_STARTED,
    SET_CONFIRMATION_PHRASE_SUCCESS,
    SET_CONFIRMATION_PHRASE_FAILURE,
    SET_UUID,
    LOGIN_STARTED,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SET_AGREEMENT_STATUS,
    IMPORT_WALLET_STARTED,
    IMPORT_WALLET_SUCCESS,
    IMPORT_WALLET_FAILURE
} from "./types";

const initialState = {
    test: false,
    setPhraseStarted: false,
    setPhrase: false,
    setPhraseErr: false,
    authorizationStatus: !!localStorage.getItem('owt'),
    uuidStatus: !!localStorage.getItem('owt-uuid'),
    phraseStatus: JSON.parse(localStorage.getItem('owt-ps')),
    agreementStatus: !!localStorage.getItem('owt-as'),
    registrationStarted: false,
    registration: false,
    registrationErr: false,
    loginStarted: false,
    login: false,
    loginErr: false,
    getPhraseStarted: false,
    getPhrase: false,
    getPhraseErr: false,
    getConfirmationPhraseStarted: false,
    getConfirmationPhrase: false,
    getConfirmationPhraseErr: false,
    setConfirmationPhraseStarted: false,
    setConfirmationPhrase: false,
    setConfirmationPhraseErr: false,
    createdPhrase: ['word1', 'word2', 'word3', 'word4', 'word5', 'word6', 'word7', 'word8', 'word9', 'word10', 'word11', 'word12'],
    confirmPhrase: {phrase: ['word1', '-', 'word3', '-', '-', '-', 'word7', 'word8', 'word9', 'word10', 'word11', 'word12'], options: ['word1', 'word2', 'word3', 'word4', 'word5', 'word6']},
    importWalletStarted: false,
    importWallet: false,
    importWalletErr: false,
};

export function mainReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case SET_AUTHORIZATION_STATUS:
            return {
                ...state,
                authorizationStatus: action.payload
            };
        case SET_AGREEMENT_STATUS:
            return {
                ...state,
                agreementStatus: action.payload
            };
        case SET_PHRASE_STATUS:
            return {
                ...state,
                phraseStatus: action.payload
            };
        case SET_UUID:
            return {
                ...state,
                uuidStatus: action.payload
            };
        case SET_PHRASE_STARTED:
            return {
                ...state,
                setPhraseStarted: true
            };
        case SET_PHRASE_SUCCESS:
            return {
                ...state,
                setPhrase: action.payload,
                setPhraseStarted: false
            };
        case SET_PHRASE_FAILURE:
            return {
                ...state,
                setPhraseErr: action.payload,
                setPhraseStarted: false
            };
        case REGISTRATION_STARTED:
            return {
                ...state,
                registrationStarted: true
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                registration: action.payload,
                registrationStarted: false
            };
        case REGISTRATION_FAILURE:
            return {
                ...state,
                registrationErr: action.payload,
                registrationStarted: false
            };
        case GET_PHRASE_STARTED:
            return {
                ...state,
                getPhraseStarted: true
            };
        case GET_PHRASE_SUCCESS:
            return {
                ...state,
                getPhrase: action.payload,
                getPhraseStarted: false
            };
        case GET_PHRASE_FAILURE:
            return {
                ...state,
                getPhraseErr: action.payload,
                getPhraseStarted: false
            };
        case GET_CONFIRMATION_PHRASE_STARTED:
            return {
                ...state,
                getConfirmationPhraseStarted: true
            };
        case GET_CONFIRMATION_PHRASE_SUCCESS:
            return {
                ...state,
                getConfirmationPhrase: action.payload,
                getConfirmationPhraseStarted: false
            };
        case GET_CONFIRMATION_PHRASE_FAILURE:
            return {
                ...state,
                getConfirmationPhraseErr: action.payload,
                getConfirmationPhraseStarted: false
            };
        case SET_CONFIRMATION_PHRASE_STARTED:
            return {
                ...state,
                setConfirmationPhraseStarted: true
            };
        case SET_CONFIRMATION_PHRASE_SUCCESS:
            return {
                ...state,
                setConfirmationPhrase: action.payload,
                setConfirmationPhraseStarted: false
            };
        case SET_CONFIRMATION_PHRASE_FAILURE:
            return {
                ...state,
                setConfirmationPhraseErr: action.payload,
                setConfirmationPhraseStarted: false
            };
        case LOGIN_STARTED:
            return {
                ...state,
                loginStarted: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
                loginStarted: false
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginErr: action.payload,
                loginStarted: false
            };
        case IMPORT_WALLET_STARTED:
            return {
                ...state,
                importWalletStarted: true
            };
        case IMPORT_WALLET_SUCCESS:
            return {
                ...state,
                importWallet: action.payload,
                importWalletStarted: false
            };
        case IMPORT_WALLET_FAILURE:
            return {
                ...state,
                importWalletErr: action.payload,
                importWalletStarted: false
            };
        default:
            return state;
    }
}

