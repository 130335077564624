import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";

import CustomBtn from "../../components/_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";

import phraseBg1 from '../../theme/assets/svg/omt_phrase_bg1.svg';
import phraseBg2 from '../../theme/assets/svg/omt_phrase_bg2.svg';
import phraseBg3 from '../../theme/assets/svg/omt_phrase_bg3.svg';
import phraseBg4 from '../../theme/assets/svg/omt_phrase_bg4.svg';
import phraseBg5 from '../../theme/assets/svg/omt_phrase_bg5.svg';
import phraseBg6 from '../../theme/assets/svg/omt_phrase_bg6.svg';
import phraseBg7 from '../../theme/assets/svg/omt_phrase_bg7.svg';
import phraseBg8 from '../../theme/assets/svg/omt_phrase_bg8.svg';
import phraseBg9 from '../../theme/assets/svg/omt_phrase_bg9.svg';
import phraseBg10 from '../../theme/assets/svg/omt_phrase_bg10.svg';
import phraseBg11 from '../../theme/assets/svg/omt_phrase_bg11.svg';
import phraseBg12 from '../../theme/assets/svg/omt_phrase_bg12.svg';
import {getPhrase} from "../../store/main/action";
import CustomRouter from "../../components/customRouter";




const SeedCreatePage = () => {
    const dispatch = useDispatch();
    
    const phrase = useSelector(store => store.main.getPhrase);
    const phraseImages = [phraseBg1, phraseBg2, phraseBg3, phraseBg4, phraseBg5, phraseBg6, phraseBg7, phraseBg8, phraseBg9, phraseBg10, phraseBg11, phraseBg12];

    const [copyStatus, setCopyStatus] = useState(false);

    const copyBtnHandler = () =>{
        navigator.clipboard.writeText(phrase.join(' '));
        setCopyStatus(true);
    }
    
    useEffect(()=>{
        dispatch(getPhrase())
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.backIconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Seed phrase</div>
                    </div>
                    <div className={`${Styles.titleNote}`}>
                        <div className={`${Styles.titleNoteItem}`}>Your seed phrase are here:</div>
                    </div>
                    {phrase ?
                        <div className={`${Styles.phraseWrap}`}>
                            <div className={`${Styles.phraseBox}`}>
                                {phrase.map((item, index)=>{
                                    return (
                                        <div
                                            className={`${Styles.phraseBoxItem}`}
                                            key={'createdPhrase' + index}
                                            style={{backgroundImage: `url(${phraseImages[index]})`}}
                                        >
                                            {item}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`${Styles.btn}`} onClick={()=>{copyBtnHandler()}}>{copyStatus ? 'Copied' : 'Copy'}</div>
                        </div> :
                        <div className={`${Styles.loadWrap}`}>Loading...</div>
                    }
                    <div className={`${Styles.contentNote}`}>
                        Please memorize or copy this phrase to your device, you will need to repeat it in the next window
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Next'} link='/seed-confirm' btnType={copyStatus ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='seed-create'/>
        </section>
    );
};

export default SeedCreatePage;