import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";

import CustomBtn from "../../components/_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";

import phraseBg1 from '../../theme/assets/svg/omt_phrase_bg1.svg';
import phraseBg2 from '../../theme/assets/svg/omt_phrase_bg2.svg';
import phraseBg3 from '../../theme/assets/svg/omt_phrase_bg3.svg';
import phraseBg4 from '../../theme/assets/svg/omt_phrase_bg4.svg';
import phraseBg5 from '../../theme/assets/svg/omt_phrase_bg5.svg';
import phraseBg6 from '../../theme/assets/svg/omt_phrase_bg6.svg';
import phraseBg7 from '../../theme/assets/svg/omt_phrase_bg7.svg';
import phraseBg8 from '../../theme/assets/svg/omt_phrase_bg8.svg';
import phraseBg9 from '../../theme/assets/svg/omt_phrase_bg9.svg';
import phraseBg10 from '../../theme/assets/svg/omt_phrase_bg10.svg';
import phraseBg11 from '../../theme/assets/svg/omt_phrase_bg11.svg';
import phraseBg12 from '../../theme/assets/svg/omt_phrase_bg12.svg';
import reloadIcon from '../../theme/assets/svg/owt_reload_icon.svg';

import {
    getConfirmationPhrase,
    getConfirmationPhraseSuccess,
    setConfirmationPhrase,
    setConfirmationPhraseFailure
} from "../../store/main/action";
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";




const SeedConfirmPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const confirmPhraseData = useSelector(store => store.main.getConfirmationPhrase);
    const confirmPhraseErrStatus = useSelector(store => store.main.setConfirmationPhraseErr);

    const phraseImages = [phraseBg1, phraseBg2, phraseBg3, phraseBg4, phraseBg5, phraseBg6, phraseBg7, phraseBg8, phraseBg9, phraseBg10, phraseBg11, phraseBg12];

    const [currentPhrase, setCurrentPhrase] = useState(confirmPhraseData?.phrase ? confirmPhraseData.phrase : false);
    const [currentWordIndex, setCurrentWordIndex] = useState(-1);

    const changePhraseHandler = (word) =>{
        if (currentWordIndex !== -1){
            let newCurrentPhrase = currentPhrase;
            newCurrentPhrase[currentWordIndex] = word;
            setCurrentPhrase([...newCurrentPhrase]);
        }
    }

    const rememberBtnHandler = ()=>{
        navigate('/seed-create');
        setCurrentWordIndex(-1);
        setCurrentPhrase(false);
        dispatch(getConfirmationPhraseSuccess(false));
        dispatch(setConfirmationPhraseFailure(false));
    }

    const mainBtnHandler = () =>{
        if (currentPhrase.indexOf('') === -1){
            dispatch(setConfirmationPhrase({phrase: currentPhrase.join(' ')}))
        }
    }

    const reloadHandler = () =>{
        dispatch(getConfirmationPhrase());
    }
    
    useEffect(()=>{
        if (confirmPhraseData?.phrase){
            setCurrentPhrase(confirmPhraseData.phrase)
        }
    },[confirmPhraseData])
    
    useEffect(()=>{
        if (currentPhrase){
            setCurrentWordIndex(currentPhrase.indexOf(''))
        }
    },[currentPhrase])
    
    useEffect(()=>{
        dispatch(getConfirmationPhrase());
    },[dispatch])
    
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.backIconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Seed phrase</div>
                        <div
                            className={`${Styles.reloadBtn}`}
                            onClick={()=>{
                                reloadHandler();
                            }}
                        >
                            <img src={reloadIcon} alt="reload"/>
                        </div>
                    </div>
                    <div className={`${Styles.titleNote}`}>
                        <div className={`${Styles.titleNoteItem}`}>Insert the missing words:</div>
                    </div>

                    {confirmPhraseData ?
                        <div className={`${Styles.phraseWrap}`}>
                            {confirmPhraseErrStatus ?
                                <>
                                    <div className={`${Styles.phraseBox}`}>
                                        {confirmPhraseData?.phrase?.map((item, index)=>{
                                            return (
                                                <div
                                                    className={`${Styles.phraseBoxItem} ${item === '' ? Styles.phraseBoxItemPassive : null}`}
                                                    key={'confirmPhrase' + index}
                                                    style={{backgroundImage: `url(${phraseImages[index]})`}}
                                                >
                                                    <span>{item}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={`${Styles.errNoteWrap}`}>
                                        <div className={`${Styles.errNote}`}>
                                            The words you entered do not match the original seed prashe. Please go back and check the correctness of the recorded phrase again
                                        </div>
                                    </div>
                                </>:
                                <>
                                    <div className={`${Styles.phraseBox}`}>
                                        {currentPhrase ? currentPhrase?.map((item, index)=>{
                                            return (
                                                <div
                                                    className={`${Styles.phraseBoxItem} ${item === '' ? Styles.phraseBoxItemPassive : null} ${currentWordIndex === index ? Styles.phraseBoxItemActive : null}`}
                                                    key={'confirmPhrase' + index}
                                                    style={{backgroundImage: `url(${phraseImages[index]})`}}
                                                >
                                                    <span>{item === '' ? '-' : item}</span>
                                                </div>
                                            )
                                        }) : null}
                                    </div>
                                    <div className={`${Styles.optionsWrap}`}>
                                        <div className={`${Styles.optionsBox}`}>
                                            {confirmPhraseData?.options?.map((item, index)=>{
                                                return (
                                                    <div
                                                        className={`${Styles.optionsBoxItem}`}
                                                        key={'confirmOptions' + index}
                                                        onClick={()=>{changePhraseHandler(item)}}
                                                    >
                                                        {item}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            }
                        </div> :
                        <div className={`${Styles.loadWrap}`}>Loading...</div>
                    }
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    {
                        confirmPhraseErrStatus ?
                            <CustomBtn text={'Going back to remember'} link='/seed-create' btnType={'wb'} action={rememberBtnHandler}/> :
                            <CustomBtn text={'Сonfirm'} link='/seed-confirm' btnType={currentPhrase ? currentPhrase?.indexOf('') === -1 ? 'wb' : 'db' : 'db'} action={mainBtnHandler}/>
                    }
                </div>
            </div>
            <CustomRouter page='seed-confirm'/>
        </section>
    );
};

export default SeedConfirmPage;