import axios from 'axios';

import {
    TEST,
    SET_CURRENT_BLOCKCHAIN,
    GET_BLOCKCHAIN_STARTED,
    GET_BLOCKCHAIN_SUCCESS,
    GET_BLOCKCHAIN_FAILURE,
    GET_MAIN_WALLET_DATA_STARTED,
    GET_MAIN_WALLET_DATA_SUCCESS,
    GET_MAIN_WALLET_DATA_FAILURE,
    SET_NETWORK_MODAL_STATUS,
    SET_CURRENT_WALLET_NETWORK_ID,
    GET_WALLETS_STARTED,
    GET_WALLETS_SUCCESS,
    GET_WALLETS_FAILURE,
    SET_WALLET_ID,
    SET_MESSAGE_MODAL_STATUS,
    CHANGE_NETWORK_STARTED,
    CHANGE_NETWORK_SUCCESS,
    CHANGE_NETWORK_FAILURE,
    GET_WALLET_ASSETS_STARTED,
    GET_WALLET_ASSETS_SUCCESS,
    GET_WALLET_ASSETS_FAILURE,
    GET_TOKENS_STARTED,
    GET_TOKENS_SUCCESS,
    GET_TOKENS_FAILURE,
    GET_CURRENT_WALLET_ASSETS_STARTED,
    GET_CURRENT_WALLET_ASSETS_SUCCESS,
    GET_CURRENT_WALLET_ASSETS_FAILURE,
    ADD_WALLET_ASSETS_STARTED,
    ADD_WALLET_ASSETS_SUCCESS,
    ADD_WALLET_ASSETS_FAILURE,
    REMOVE_WALLET_ASSETS_STARTED,
    REMOVE_WALLET_ASSETS_SUCCESS,
    REMOVE_WALLET_ASSETS_FAILURE,
    GET_CURRENCY_GROUPS_STARTED,
    GET_CURRENCY_GROUPS_SUCCESS,
    GET_CURRENCY_GROUPS_FAILURE,
    SET_CURRENCY_GROUP,
    CREATE_NEW_ACCOUNT_STARTED,
    CREATE_NEW_ACCOUNT_SUCCESS,
    CREATE_NEW_ACCOUNT_FAILURE,
    GET_WALLET_ASSETS_HISTORY_STARTED,
    GET_WALLET_ASSETS_HISTORY_SUCCESS,
    GET_WALLET_ASSETS_HISTORY_FAILURE,
    GET_WALLET_ASSETS_FEE_STARTED,
    GET_WALLET_ASSETS_FEE_SUCCESS,
    GET_WALLET_ASSETS_FEE_FAILURE,
    SEND_CRYPTO_STARTED,
    SEND_CRYPTO_SUCCESS,
    SEND_CRYPTO_FAILURE,
    CHANGE_ACCOUNT_STATUS,
    GET_VALIDATE_ADDRESS_STATUS_STARTED,
    GET_VALIDATE_ADDRESS_STATUS_SUCCESS,
    GET_VALIDATE_ADDRESS_STATUS_FAILURE,
    SET_SEND_MODAL_STATUS,
    GET_TRANSACTION_DETAILS_STARTED,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_TRANSACTION_DETAILS_FAILURE,
    GET_CONNECT_CODE_STARTED,
    GET_CONNECT_CODE_SUCCESS,
    GET_CONNECT_CODE_FAILURE
} from "./types";

import {api} from "../../api";
const host = api.host;

const headWT = ()=>{
    const token = localStorage.getItem('owt');
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
}

export const test = data => ({
    type: TEST,
    payload: data
});

export const setNetworkModalStatus = status => ({
    type: SET_NETWORK_MODAL_STATUS,
    payload: status
});

export const changeAccountStatus = status => ({
    type: CHANGE_ACCOUNT_STATUS,
    payload: status
});

export const setCurrencyGroup = id => ({
    type: SET_CURRENCY_GROUP,
    payload: id
});

export const setMessageModalStatus = status => ({
    type: SET_MESSAGE_MODAL_STATUS,
    payload: status
});

export const setSendModalStatus = status => ({
    type: SET_SEND_MODAL_STATUS,
    payload: status
});

export const setWalletId = id => ({
    type: SET_WALLET_ID,
    payload: id
});

export const setCurrentWalletNetworkId = id => ({
    type: SET_CURRENT_WALLET_NETWORK_ID,
    payload: id
});

export const setCurrentBlockchain = blockchain => ({
    type: SET_CURRENT_BLOCKCHAIN,
    payload: blockchain
});

export const getBlockchain = () => {
    let url = host + api.wallet.blockchains;


    return dispatch => {
        dispatch(getBlockchainStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getBlockchainSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getBlockchainFailure(err));
            });
    };
};

const getBlockchainStarted = () => ({
    type: GET_BLOCKCHAIN_STARTED
});

export const getBlockchainSuccess = serverReport => ({
    type: GET_BLOCKCHAIN_SUCCESS,
    payload: serverReport
});

export const getBlockchainFailure = error => ({
    type: GET_BLOCKCHAIN_FAILURE,
    payload: error
});


export const getMainWalletData = (id, reloadAssetsStatus) => {
    let url;
    if (id){
        url = host + api.wallet.mainData + `/${id}`;
    } else {
        url = host + api.wallet.mainData;
    }


    return dispatch => {
        dispatch(getMainWalletDataStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getMainWalletDataSuccess(res.data.response));
                dispatch(setCurrentWalletNetworkId(res.data.response?.account?.network_id));
                if (reloadAssetsStatus){
                    dispatch(getWalletAssets());
                }
            })
            .catch(err => {
                dispatch(getMainWalletDataFailure(err));
            });
    };
};

const getMainWalletDataStarted = () => ({
    type: GET_MAIN_WALLET_DATA_STARTED
});

export const getMainWalletDataSuccess = serverReport => ({
    type: GET_MAIN_WALLET_DATA_SUCCESS,
    payload: serverReport
});

export const getMainWalletDataFailure = error => ({
    type: GET_MAIN_WALLET_DATA_FAILURE,
    payload: error
});


export const getWallets = (blockchain_id, network_id) => {
    let url = host + api.wallet.wallets + `?blockchain_id=${blockchain_id}&network_id=${network_id}`;


    return dispatch => {
        dispatch(getWalletsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletsFailure(err));
            });
    };
};

const getWalletsStarted = () => ({
    type: GET_WALLETS_STARTED
});

export const getWalletsSuccess = serverReport => ({
    type: GET_WALLETS_SUCCESS,
    payload: serverReport
});

export const getWalletsFailure = error => ({
    type: GET_WALLETS_FAILURE,
    payload: error
});

export const changeNetwork = (network_id) => {
    let url = host + api.wallet.changeNetworks + `?network_id=${network_id}`;


    return dispatch => {
        dispatch(changeNetworkStarted());
        axios.put(url,null, headWT())
            .then(res => {
                dispatch(changeNetworkSuccess(res.data.response));
                dispatch(getMainWalletData(null, true));
            })
            .catch(err => {
                dispatch(changeNetworkFailure(err));
            });
    };
};

const changeNetworkStarted = () => ({
    type: CHANGE_NETWORK_STARTED
});

export const changeNetworkSuccess = serverReport => ({
    type: CHANGE_NETWORK_SUCCESS,
    payload: serverReport
});

export const changeNetworkFailure = error => ({
    type: CHANGE_NETWORK_FAILURE,
    payload: error
});

export const getWalletAssets = () => {
    // let url = host + api.wallet.assets + `?network_id=${network_id}`;
    let url = host + api.wallet.assets;

    return dispatch => {
        dispatch(getWalletAssetsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletAssetsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletAssetsFailure(err));
            });
    };
};

const getWalletAssetsStarted = () => ({
    type: GET_WALLET_ASSETS_STARTED
});

export const getWalletAssetsSuccess = serverReport => ({
    type: GET_WALLET_ASSETS_SUCCESS,
    payload: serverReport
});

export const getWalletAssetsFailure = error => ({
    type: GET_WALLET_ASSETS_FAILURE,
    payload: error
});


export const getTokens = (search) => {
    let url;
    if (search){
        url = host + api.wallet.tokens + `?search=${search}`;
    } else {
        url = host + api.wallet.tokens;
    }

    return dispatch => {
        dispatch(getTokensStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getTokensSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getTokensFailure(err));
            });
    };
};

const getTokensStarted = () => ({
    type: GET_TOKENS_STARTED
});

export const getTokensSuccess = serverReport => ({
    type: GET_TOKENS_SUCCESS,
    payload: serverReport
});

export const getTokensFailure = error => ({
    type: GET_TOKENS_FAILURE,
    payload: error
});

export const getCurrentWalletAssets = (id) => {
    let url = host + api.wallet.assets + `/${id}`;

    return dispatch => {
        dispatch(getCurrentWalletAssetsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCurrentWalletAssetsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCurrentWalletAssetsFailure(err));
            });
    };
};

const getCurrentWalletAssetsStarted = () => ({
    type: GET_CURRENT_WALLET_ASSETS_STARTED
});

export const getCurrentWalletAssetsSuccess = serverReport => ({
    type: GET_CURRENT_WALLET_ASSETS_SUCCESS,
    payload: serverReport
});

export const getCurrentWalletAssetsFailure = error => ({
    type: GET_CURRENT_WALLET_ASSETS_FAILURE,
    payload: error
});


export const addWalletAssets = (id) => {
    let url = host + api.wallet.addAssets + `/${id}`;

    return dispatch => {
        dispatch(addWalletAssetsStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(addWalletAssetsSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(addWalletAssetsFailure(err));
            });
    };
};

const addWalletAssetsStarted = () => ({
    type: ADD_WALLET_ASSETS_STARTED
});

export const addWalletAssetsSuccess = serverReport => ({
    type: ADD_WALLET_ASSETS_SUCCESS,
    payload: serverReport
});

export const addWalletAssetsFailure = error => ({
    type: ADD_WALLET_ASSETS_FAILURE,
    payload: error
});


export const removeWalletAssets = (id) => {
    let url = host + api.wallet.removeAssets + `/${id}`;

    return dispatch => {
        dispatch(removeWalletAssetsStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(removeWalletAssetsSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(removeWalletAssetsFailure(err));
            });
    };
};

const removeWalletAssetsStarted = () => ({
    type: REMOVE_WALLET_ASSETS_STARTED
});

export const removeWalletAssetsSuccess = serverReport => ({
    type: REMOVE_WALLET_ASSETS_SUCCESS,
    payload: serverReport
});

export const removeWalletAssetsFailure = error => ({
    type: REMOVE_WALLET_ASSETS_FAILURE,
    payload: error
});


export const getCurrencyGroups = () => {
    let url = host + api.wallet.currencyGroups;

    return dispatch => {
        dispatch(getCurrencyGroupsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getCurrencyGroupsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getCurrencyGroupsFailure(err));
            });
    };
};

const getCurrencyGroupsStarted = () => ({
    type: GET_CURRENCY_GROUPS_STARTED
});

export const getCurrencyGroupsSuccess = serverReport => ({
    type: GET_CURRENCY_GROUPS_SUCCESS,
    payload: serverReport
});

export const getCurrencyGroupsFailure = error => ({
    type: GET_CURRENCY_GROUPS_FAILURE,
    payload: error
});


export const createNewAccount = (data) => {
    let url = host + api.wallet.wallets;

    return dispatch => {
        dispatch(createNewAccountStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(createNewAccountSuccess(res.data.success));
            })
            .catch(err => {
                dispatch(createNewAccountFailure(err.response.data.errors));
            });
    };
};

const createNewAccountStarted = () => ({
    type: CREATE_NEW_ACCOUNT_STARTED
});

export const createNewAccountSuccess = serverReport => ({
    type: CREATE_NEW_ACCOUNT_SUCCESS,
    payload: serverReport
});

export const createNewAccountFailure = error => ({
    type: CREATE_NEW_ACCOUNT_FAILURE,
    payload: error
});


export const getWalletAssetsHistory = (id) => {
    let url = host + api.wallet.assetsHistory + `/${id}`;

    return dispatch => {
        dispatch(getWalletAssetsHistoryStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletAssetsHistorySuccess(res.data.response?.data));
            })
            .catch(err => {
                dispatch(getWalletAssetsHistoryFailure(err));
            });
    };
};

const getWalletAssetsHistoryStarted = () => ({
    type: GET_WALLET_ASSETS_HISTORY_STARTED
});

export const getWalletAssetsHistorySuccess = serverReport => ({
    type: GET_WALLET_ASSETS_HISTORY_SUCCESS,
    payload: serverReport
});

export const getWalletAssetsHistoryFailure = error => ({
    type: GET_WALLET_ASSETS_HISTORY_FAILURE,
    payload: error
});


export const getWalletAssetsFee = (id, data) => {
    let url = host + api.wallet.assets + `/${id}/fee?wallet=${data.wallet}&amount=${data.amount}`;

    return dispatch => {
        dispatch(getWalletAssetsFeeStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getWalletAssetsFeeSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getWalletAssetsFeeFailure(err));
            });
    };
};

const getWalletAssetsFeeStarted = () => ({
    type: GET_WALLET_ASSETS_FEE_STARTED
});

export const getWalletAssetsFeeSuccess = serverReport => ({
    type: GET_WALLET_ASSETS_FEE_SUCCESS,
    payload: serverReport
});

export const getWalletAssetsFeeFailure = error => ({
    type: GET_WALLET_ASSETS_FEE_FAILURE,
    payload: error
});


export const sendCrypto = (id, data) => {
    let url = host + api.wallet.assets + `/${id}/send`;

    return dispatch => {
        dispatch(sendCryptoStarted());
        axios.post(url, data, headWT())
            .then(res => {
                dispatch(sendCryptoSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(sendCryptoFailure(err.response.data.errors));
            });
    };
};

const sendCryptoStarted = () => ({
    type: SEND_CRYPTO_STARTED
});

export const sendCryptoSuccess = serverReport => ({
    type: SEND_CRYPTO_SUCCESS,
    payload: serverReport
});

export const sendCryptoFailure = error => ({
    type: SEND_CRYPTO_FAILURE,
    payload: error
});


export const getValidateAddressStatus = (id, address) => {
    let url = host + api.wallet.assets + `/${id}/validate-address?address=${address}`;

    return dispatch => {
        dispatch(getValidateAddressStatusStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getValidateAddressStatusSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getValidateAddressStatusFailure(err.response.data.errors));
            });
    };
};

const getValidateAddressStatusStarted = () => ({
    type: GET_VALIDATE_ADDRESS_STATUS_STARTED
});

export const getValidateAddressStatusSuccess = serverReport => ({
    type: GET_VALIDATE_ADDRESS_STATUS_SUCCESS,
    payload: serverReport
});

export const getValidateAddressStatusFailure = error => ({
    type: GET_VALIDATE_ADDRESS_STATUS_FAILURE,
    payload: error
});

export const getTransactionDetails = (hash) => {
    let url = host + api.wallet.transactionDetails + `?hash=${hash}`;

    return dispatch => {
        dispatch(getTransactionDetailsStarted());
        axios.get(url, headWT())
            .then(res => {
                dispatch(getTransactionDetailsSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getTransactionDetailsFailure(err));
            });
    };
};

const getTransactionDetailsStarted = () => ({
    type: GET_TRANSACTION_DETAILS_STARTED
});

export const getTransactionDetailsSuccess = serverReport => ({
    type: GET_TRANSACTION_DETAILS_SUCCESS,
    payload: serverReport
});

export const getTransactionDetailsFailure = error => ({
    type: GET_TRANSACTION_DETAILS_FAILURE,
    payload: error
});

export const getConnectCode = () => {
    let url = host + api.wallet.connect;

    return dispatch => {
        dispatch(getConnectCodeStarted());
        axios.post(url, null, headWT())
            .then(res => {
                dispatch(getConnectCodeSuccess(res.data.response));
            })
            .catch(err => {
                dispatch(getConnectCodeFailure(err));
            });
    };
};

const getConnectCodeStarted = () => ({
    type: GET_CONNECT_CODE_STARTED
});

export const getConnectCodeSuccess = serverReport => ({
    type: GET_CONNECT_CODE_SUCCESS,
    payload: serverReport
});

export const getConnectCodeFailure = error => ({
    type: GET_CONNECT_CODE_FAILURE,
    payload: error
});