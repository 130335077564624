import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import copyIconActive from "../../theme/assets/svg/omt_copy_icon_active.svg";
import copyIconPassive from "../../theme/assets/svg/omt_copy_icon_passive.svg";
import CryptoIcon from "../_UI/cryptoIcon";
import sendIcon from "../../theme/assets/svg/omt_action_send_icon.svg";
import receiveIcon from "../../theme/assets/svg/omt_action_receive_icon.svg";
import {removeWalletAssets, removeWalletAssetsSuccess, setMessageModalStatus} from "../../store/wallet/action";




const WalletCurrentAssetsMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    
    const currentAsset = useSelector(store => store.wallet.currentWalletAssets);
    const removeWalletAssetsStatus = useSelector(store => store.wallet.removeWalletAssets);
    
    const [copyAddressStatus, setCopyAddressStatus] = useState(false);

    console.log(currentAsset)
    

    const copyAddressHandler = () =>{
        if (currentAsset){
            navigator.clipboard.writeText(currentAsset?.address);
            setCopyAddressStatus(true);
        }
    }
    
    useEffect(()=>{
        if (removeWalletAssetsStatus){
            dispatch(setMessageModalStatus('Success'));
            navigate('/wallet');
            dispatch(removeWalletAssetsSuccess(false));
        }
    },[dispatch, navigate, removeWalletAssetsStatus])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} >
                    <div className={`${Styles.backBtnWrap}`} onClick={()=>{navigate('/wallet')}}>
                        <div className={`${Styles.iconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Back</div>
                    </div>
                    {currentAsset && currentAsset.is_primary === false ?
                        <div className={`${Styles.removeBtn}`} onClick={()=>{dispatch(removeWalletAssets(currentAsset?.id))}}>
                            Remove Asset
                        </div> : null
                    }
                </div>
                <div className={`${Styles.walletWrap}`}>
                    <div className={`${Styles.walletBox}`}>
                        <div className={`${Styles.nameRow}`}>
                            <CryptoIcon short_name={currentAsset?.currency_code} bigSize={true}/>
                        </div>
                        <div className={`${Styles.balanceRow}`}>
                            <div className={`${Styles.amount}`}>{currentAsset?.amount * 1} {currentAsset?.currency_code}</div>
                            <div className={`${Styles.amountRate}`}>({((currentAsset?.amount * 1) * (currentAsset?.currency_rate * 1)).toFixed(2)}$)</div>
                        </div>
                        <div className={`${Styles.addressRow}`}>
                            <div className={`${Styles.title6}`}>Wallet address:</div>
                            <div className={`${Styles.addressBox}`}>
                                <div className={`${Styles.address}`}>{currentAsset ? currentAsset?.address : 'Loading...'}</div>
                                <div className={`${Styles.copyBtn}`} onClick={()=>{copyAddressHandler()}}>
                                    <img src={copyAddressStatus ? copyIconActive : copyIconPassive} alt="copy address btn"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.actionsWrap}`}>
                    <div className={`${Styles.actionsItem}`} onClick={()=>{navigate(`/send/${id}`)}}>
                        <div className={`${Styles.actionsItemImgWrap}`}>
                            <img src={sendIcon} alt="Send"/>
                        </div>
                        <div className={`${Styles.actionsItemName}`}>Send</div>
                    </div>
                    <div className={`${Styles.actionsItem}`} onClick={()=>{navigate(`/receive/${currentAsset?.address}/${currentAsset?.blockchain}`)}}>
                        <div className={`${Styles.actionsItemImgWrap}`}>
                            <img src={receiveIcon} alt="Receive"/>
                        </div>
                        <div className={`${Styles.actionsItemName}`}>Receive</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WalletCurrentAssetsMain;
