import React from "react";
import Moment from "react-moment";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import sendIcon from '../../theme/assets/svg/omt_action_send_icon.svg';
import receiveIcon from '../../theme/assets/svg/omt_action_receive_icon.svg';
import {shortHandler} from "../../helpers/shortHadler";
import {useNavigate} from "react-router-dom";




const WalletCurrentAssetsHistory = () => {
    const navigate = useNavigate();

    const walletAssetsHistory = useSelector(store => store.wallet.walletAssetsHistory);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                {walletAssetsHistory ? walletAssetsHistory.length > 0 ?
                    walletAssetsHistory.map((item, index)=>{
                        return (
                            <div className={`${Styles.contentWrapItem}`} key={'assetsHistory' + index} onClick={()=>{navigate(`/transaction/details/${item.hash}`)}}>
                                <div className={`${Styles.infoBox}`}>
                                    <div className={`${Styles.iconWrap}`}>
                                        <img src={item.type === 'sent' ? sendIcon : receiveIcon} alt="transaction"/>
                                    </div>
                                    <div className={`${Styles.textWrap}`}>
                                        <div className={`${Styles.textWrapType}`}>{item.type} ({shortHandler(item.hash, 4)})</div>
                                        <div className={`${Styles.textWrapDate}`}><Moment format="DD-MM-YYYY hh:mm:ss">{item.date}</Moment></div>
                                    </div>
                                </div>
                                <div className={`${Styles.amountBox}`}>{(item.amount * 1).toFixed(2)}</div>
                            </div>
                        )
                    }) :
                    <div className={`${Styles.emptyNote}`}>You don't have any transactions yet</div> :
                    <div className={`${Styles.emptyNote}`}>Loading...</div>
                }
            </div>
        </section>
    );
};

export default WalletCurrentAssetsHistory;
