import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import NextBtn from "../../components/_UI/nextBtn";
import aboutIcon from "../../theme/assets/svg/omt_about_icon.svg";
import lockIcon from "../../theme/assets/svg/omt_lock_icon.svg";
import {setAuthorizationStatus} from "../../store/main/action";
import {useDispatch} from "react-redux";





const WalletSettingsPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentHover, setCurrentHover] = useState('');


    const lockHandler = () =>{
        localStorage.removeItem('owt');
        dispatch(setAuthorizationStatus(false));
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Settings</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'connect' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('connect')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{navigate('/wallet-connect')}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={aboutIcon} alt="connect"/>
                                </div>
                                <div className={`${Styles.name}`}>Connect</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>{''}</div>
                        </div>
                        <NextBtn isHover={currentHover === 'connect'}/>
                    </div>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'about' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('about')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{navigate('/about')}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={aboutIcon} alt="about"/>
                                </div>
                                <div className={`${Styles.name}`}>About us</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>V 1.0.2</div>
                        </div>
                        <NextBtn isHover={currentHover === 'about'}/>
                    </div>
                    <div
                        className={`${Styles.contentBoxItem} ${currentHover === 'lock' ? Styles.contentBoxItemActive : null}`}
                        onMouseEnter={()=>{setCurrentHover('lock')}}
                        onMouseLeave={()=>{setCurrentHover('')}}
                        onClick={()=>{lockHandler()}}
                    >
                        <div className={`${Styles.rowContent}`}>
                            <div className={`${Styles.rowContentName}`}>
                                <div className={`${Styles.iconWrap}`}>
                                    <img src={lockIcon} alt="lock"/>
                                </div>
                                <div className={`${Styles.name}`}>Lock</div>
                            </div>
                            <div className={`${Styles.rowContentValue}`}>{''}</div>
                        </div>
                        <NextBtn isHover={currentHover === 'lock'}/>
                    </div>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletSettingsPage;