import React from "react";
import Styles from './styles.module.scss';
import {useNavigate} from "react-router-dom";


const CustomBtn = (props) => {
    const {text, link, btnType, action, actionParams} = props;
    const navigate = useNavigate();


    const btnTypeHandler = () =>{
        switch (btnType){
            case 'wb':
                return Styles.btnWB;
            case 'db':
                return Styles.btnDB;
            default:
                return Styles.btn
        }
    }



    return (
        <section
            className={`${btnTypeHandler()}`}
            onClick={()=>{
                if (action){
                    if (actionParams){
                        action(actionParams)
                    } else {
                        action()
                    }
                } else {
                    if (link){
                        navigate(link)
                    }
                }
            }}
        >
            {text}
        </section>
    );
};

export default CustomBtn;
