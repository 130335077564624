import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getTransactionDetails,
} from "../../store/wallet/action";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";



const TransactionDetailsPage = () => {
    const {hash} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const transactionDetailsData = useSelector(store => store.wallet.transactionDetailsData);

    console.log(transactionDetailsData)
    
    useEffect(()=>{
       if (hash){
           dispatch(getTransactionDetails(hash));
       }
    },[dispatch, hash]);
    
    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} >
                    <div className={`${Styles.backBtnWrap}`} onClick={()=>{navigate('/wallet')}}>
                        <div className={`${Styles.iconWrap}`}>
                            <img src={listArrowIcon} alt="back arrow"/>
                        </div>
                        <div className={`${Styles.title4}`}>Back</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TransactionDetailsPage;