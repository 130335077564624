export const TEST = 'TEST';
export const SET_CURRENT_BLOCKCHAIN = 'SET_CURRENT_BLOCKCHAIN';
export const SET_NETWORK_MODAL_STATUS = 'SET_NETWORK_MODAL_STATUS';
export const SET_MESSAGE_MODAL_STATUS = 'SET_MESSAGE_MODAL_STATUS';
export const SET_SEND_MODAL_STATUS = 'SET_SEND_MODAL_STATUS';
export const SET_CURRENT_WALLET_NETWORK_ID = 'SET_CURRENT_WALLET_NETWORK_ID';
export const SET_WALLET_ID = 'SET_WALLET_ID';
export const SET_CURRENCY_GROUP = 'SET_CURRENCY_GROUP';
export const CHANGE_ACCOUNT_STATUS = 'CHANGE_ACCOUNT_STATUS';

export const GET_BLOCKCHAIN_STARTED = 'GET_BLOCKCHAIN_STARTED';
export const GET_BLOCKCHAIN_SUCCESS = 'GET_BLOCKCHAIN_SUCCESS';
export const GET_BLOCKCHAIN_FAILURE = 'GET_BLOCKCHAIN_FAILURE';

export const GET_MAIN_WALLET_DATA_STARTED = 'GET_MAIN_WALLET_DATA_STARTED';
export const GET_MAIN_WALLET_DATA_SUCCESS = 'GET_MAIN_WALLET_DATA_SUCCESS';
export const GET_MAIN_WALLET_DATA_FAILURE = 'GET_MAIN_WALLET_DATA_FAILURE';

export const GET_WALLETS_STARTED = 'GET_WALLETS_STARTED';
export const GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS';
export const GET_WALLETS_FAILURE = 'GET_WALLETS_FAILURE';

export const CHANGE_NETWORK_STARTED = 'CHANGE_NETWORK_STARTED';
export const CHANGE_NETWORK_SUCCESS = 'CHANGE_NETWORK_SUCCESS';
export const CHANGE_NETWORK_FAILURE = 'CHANGE_NETWORK_FAILURE';

export const GET_WALLET_ASSETS_STARTED = 'GET_WALLET_ASSETS_STARTED';
export const GET_WALLET_ASSETS_SUCCESS = 'GET_WALLET_ASSETS_SUCCESS';
export const GET_WALLET_ASSETS_FAILURE = 'GET_WALLET_ASSETS_FAILURE';

export const GET_TOKENS_STARTED = 'GET_TOKENS_STARTED';
export const GET_TOKENS_SUCCESS = 'GET_TOKENS_SUCCESS';
export const GET_TOKENS_FAILURE = 'GET_TOKENS_FAILURE';

export const GET_CURRENT_WALLET_ASSETS_STARTED = 'GET_CURRENT_WALLET_ASSETS_STARTED';
export const GET_CURRENT_WALLET_ASSETS_SUCCESS = 'GET_CURRENT_WALLET_ASSETS_SUCCESS';
export const GET_CURRENT_WALLET_ASSETS_FAILURE = 'GET_CURRENT_WALLET_ASSETS_FAILURE';

export const ADD_WALLET_ASSETS_STARTED = 'ADD_WALLET_ASSETS_STARTED';
export const ADD_WALLET_ASSETS_SUCCESS = 'ADD_WALLET_ASSETS_SUCCESS';
export const ADD_WALLET_ASSETS_FAILURE = 'ADD_WALLET_ASSETS_FAILURE';

export const REMOVE_WALLET_ASSETS_STARTED = 'REMOVE_WALLET_ASSETS_STARTED';
export const REMOVE_WALLET_ASSETS_SUCCESS = 'REMOVE_WALLET_ASSETS_SUCCESS';
export const REMOVE_WALLET_ASSETS_FAILURE = 'REMOVE_WALLET_ASSETS_FAILURE';

export const GET_CURRENCY_GROUPS_STARTED = 'GET_CURRENCY_GROUPS_STARTED';
export const GET_CURRENCY_GROUPS_SUCCESS = 'GET_CURRENCY_GROUPS_SUCCESS';
export const GET_CURRENCY_GROUPS_FAILURE = 'GET_CURRENCY_GROUPS_FAILURE';

export const CREATE_NEW_ACCOUNT_STARTED = 'CREATE_NEW_ACCOUNT_STARTED';
export const CREATE_NEW_ACCOUNT_SUCCESS = 'CREATE_NEW_ACCOUNT_SUCCESS';
export const CREATE_NEW_ACCOUNT_FAILURE = 'CREATE_NEW_ACCOUNT_FAILURE';

export const GET_WALLET_ASSETS_HISTORY_STARTED = 'GET_WALLET_ASSETS_HISTORY_STARTED';
export const GET_WALLET_ASSETS_HISTORY_SUCCESS = 'GET_WALLET_ASSETS_HISTORY_SUCCESS';
export const GET_WALLET_ASSETS_HISTORY_FAILURE = 'GET_WALLET_ASSETS_HISTORY_FAILURE';

export const GET_WALLET_ASSETS_FEE_STARTED = 'GET_WALLET_ASSETS_FEE_STARTED';
export const GET_WALLET_ASSETS_FEE_SUCCESS = 'GET_WALLET_ASSETS_FEE_SUCCESS';
export const GET_WALLET_ASSETS_FEE_FAILURE = 'GET_WALLET_ASSETS_FEE_FAILURE';

export const SEND_CRYPTO_STARTED = 'SEND_CRYPTO_STARTED';
export const SEND_CRYPTO_SUCCESS = 'SEND_CRYPTO_SUCCESS';
export const SEND_CRYPTO_FAILURE = 'SEND_CRYPTO_FAILURE';

export const GET_VALIDATE_ADDRESS_STATUS_STARTED = 'GET_VALIDATE_ADDRESS_STATUS_STARTED';
export const GET_VALIDATE_ADDRESS_STATUS_SUCCESS = 'GET_VALIDATE_ADDRESS_STATUS_SUCCESS';
export const GET_VALIDATE_ADDRESS_STATUS_FAILURE = 'GET_VALIDATE_ADDRESS_STATUS_FAILURE';

export const GET_TRANSACTION_DETAILS_STARTED = 'GET_TRANSACTION_DETAILS_STARTED';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_FAILURE = 'GET_TRANSACTION_DETAILS_FAILURE';

export const GET_CONNECT_CODE_STARTED = 'GET_CONNECT_CODE_STARTED';
export const GET_CONNECT_CODE_SUCCESS = 'GET_CONNECT_CODE_SUCCESS';
export const GET_CONNECT_CODE_FAILURE = 'GET_CONNECT_CODE_FAILURE';


