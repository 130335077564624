import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import {useNavigate, useParams} from "react-router-dom";
import CustomBtn from "../_UI/customBtn";
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {
    getCurrentWalletAssets,
    getWalletAssetsFee,
    sendCrypto,
    sendCryptoSuccess,
    setSendModalStatus
} from "../../store/wallet/action";


const WalletSendConfirmationContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id, amount, address} = useParams();

    const currentAsset = useSelector(store => store.wallet.currentWalletAssets);
    const currentFee = useSelector(store => store.wallet.walletAssetsFee);
    const sendCryptoStatus = useSelector(store => store.wallet.sendCryptoStatus);

    const feeInspectHandler = () =>{
        return Number(currentFee.fee_balance) < Number(currentFee.fee)
    }

    const mainBtnHandler = () =>{
        if (!feeInspectHandler()){
            dispatch(sendCrypto(id, {amount, address}))
        }
    }
    
    
    useEffect(()=>{
        if (sendCryptoStatus){
            dispatch(setSendModalStatus({status: true, hash: sendCryptoStatus.transaction.hash}));
            if (id){
                dispatch(getCurrentWalletAssets(id));
                if (address && amount){
                    dispatch(getWalletAssetsFee(id, {wallet: address, amount}));
                }
            }
            dispatch(sendCryptoSuccess(false));
        }
    },[address, amount, dispatch, id, navigate, sendCryptoStatus])

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.mainDataWrap}`}>
                        <div className={`${Styles.mainDataIconWrap}`}>
                            <CryptoIcon short_name={currentAsset?.currency_code} bigSize={true}/>
                        </div>
                        <div className={`${Styles.mainDataType}`}>Send</div>
                        <div className={`${Styles.mainDataAmount}`}>{amount} {currentAsset?.currency_code}</div>
                    </div>
                    <div className={`${Styles.fakeInputWrap}`}>
                        <div className={`${Styles.itemTitle}`}>From</div>
                        <div className={`${Styles.fakeInput}`}>
                            <div className={`${Styles.address}`}>{currentAsset?.address ? currentAsset.address : 'Loading...'}</div>
                        </div>
                    </div>
                    <div className={`${Styles.fakeInputWrap}`}>
                        <div className={`${Styles.itemTitle}`}>To</div>
                        <div className={`${Styles.fakeInput}`}>
                            <div className={`${Styles.address}`}>{address}</div>
                        </div>
                    </div>
                    <div className={`${Styles.commissionWrap}`}>
                        <div className={`${Styles.commissionItem}`}>
                            <div className={`${Styles.commissionItemName}`}>Transaction fee:</div>
                            <div className={`${Styles.commissionItemValue}`}>{currentFee?.fee} {currentFee?.currency}</div>
                        </div>
                        {currentAsset?.blockchain === 'Tron' ?
                            <div className={`${Styles.commissionItem}`}>
                                <div className={`${Styles.commissionItemName}`}>Bandwidth fee:</div>
                                <div className={`${Styles.commissionItemValue}`}>{currentFee?.additional?.bandwidth}</div>
                            </div> : null
                        }

                    </div>
                    {currentFee ? feeInspectHandler() ?
                        <div className={`${Styles.errMsg}`}>
                            Your {currentFee.currency} balance is not sufficient to pay the fees.
                            Fees include: {currentFee.fee} {currentFee.currency}
                        </div> : null : null
                    }
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <CustomBtn text={'Send'} link={false} btnType={!feeInspectHandler() ? 'wb' : 'db'} action={mainBtnHandler}/>
                </div>
            </div>
        </section>
    );
};

export default WalletSendConfirmationContent;
