import React from "react";
import Styles from './styles.module.scss';

import btcIcon from '../../../theme/assets/crypto/omt_crypto_btc_icon.svg';
import bnbIcon from '../../../theme/assets/crypto/omt_crypto_bnb_icon.svg';
import ethIcon from '../../../theme/assets/crypto/omt_crypto_eth_icon.svg';
import solIcon from '../../../theme/assets/crypto/omt_crypto_sol_icon.svg';
import xrpIcon from '../../../theme/assets/crypto/omt_crypto_xrp_icon.svg';
import tronIcon from '../../../theme/assets/crypto/omt_crypto_tron_icon.svg';
import omtIcon from '../../../theme/assets/crypto/omt_crypto_omt_icon.svg';
import honeeIcon from '../../../theme/assets/crypto/omt_crypto_honee_icon.svg';
import usdtBrcIcon from '../../../theme/assets/crypto/omt_crypto_icon_brc.svg';
import usdtErcIcon from '../../../theme/assets/crypto/omt_crypto_icon_erc.svg';
import usdtTrcIcon from '../../../theme/assets/crypto/omt_crypto_icon_trc.svg';
import usdcIcon from '../../../theme/assets/crypto/omt_crypto_icon_usdc.svg';
import adaIcon from '../../../theme/assets/crypto/omt_crypto_ada_icon.svg';
import maticIcon from '../../../theme/assets/crypto/omt_crypto_matic_icon.svg';

import usdtIcon from '../../../theme/assets/svg/omt_crypto_usdt_icon.svg';
import stUsdtIcon from '../../../theme/assets/svg/omt_crypto_st_usdt_icon.svg';
import htxIcon from '../../../theme/assets/svg/omt_crypto_htx_icon.svg';
import usddIcon from '../../../theme/assets/svg/omt_crypto_usdd_icon.svg';
import tusdIcon from '../../../theme/assets/svg/omt_crypto_tusd_icon.svg';
import usdjIcon from '../../../theme/assets/svg/omt_crypto_usdj_icon.svg';
import jstIcon from '../../../theme/assets/svg/omt_crypto_jst_icon.svg';
import bttIcon from '../../../theme/assets/svg/omt_crypto_btt_icon.svg';

import tonIcon from '../../../theme/assets/crypto/omt_crypto_ton_icon.svg';
import wbnbIcon from '../../../theme/assets/crypto/omt_crypto_wbnb_icon.svg';



const CryptoIcon = (props) => {
    const {short_name, bigSize, smallSize, mediumSize, mediumPSize} = props;


    const contentHandler = () =>{
        switch (short_name){
            case 'USDT':
                return (
                    <img src={usdtIcon} alt="usdt"/>
                );
            case 'TONCOIN':
                return (
                    <img src={tonIcon} alt="ton"/>
                );
            case 'WBNB':
                return (
                    <img src={wbnbIcon} alt="wbnb"/>
                );
            case 'stUSDT':
                return (
                    <img src={stUsdtIcon} alt="st usdt"/>
                );
            case 'stUSDT_TRC20':
                return (
                    <img src={stUsdtIcon} alt="st usdt"/>
                );
            case 'HTX':
                return (
                    <img src={htxIcon} alt="htx"/>
                );
            case 'HTX_TRC20':
                return (
                    <img src={htxIcon} alt="htx"/>
                );
            case 'USDD':
                return (
                    <img src={usddIcon} alt="usdd"/>
                );
            case 'USDD_TRC20':
                return (
                    <img src={usddIcon} alt="usdd"/>
                );
            case 'TUSD':
                return (
                    <img src={tusdIcon} alt="tusd"/>
                );
            case 'TUSD_TRC20':
                return (
                    <img src={tusdIcon} alt="tusd"/>
                );
            case 'USDJ':
                return (
                    <img src={usdjIcon} alt="usdj"/>
                );
            case 'USDJ_TRC20':
                return (
                    <img src={usdjIcon} alt="usdj"/>
                );
            case 'JUST':
                return (
                    <img src={jstIcon} alt="jst"/>
                );
            case 'JUST_TRC20':
                return (
                    <img src={jstIcon} alt="jst"/>
                );
            case 'BTT':
                return (
                    <img src={bttIcon} alt="btt"/>
                );
            case 'BTC':
                return (
                    <img src={btcIcon} alt="btc"/>
                );
            case 'BNB':
                return (
                    <img src={bnbIcon} alt="bnb"/>
                );
            case 'ETH':
                return (
                    <img src={ethIcon} alt="eth"/>
                );
            case 'SOL':
                return (
                    <img src={solIcon} alt="sol"/>
                );
            case 'XRP':
                return (
                    <img src={xrpIcon} alt="xrp"/>
                );
            case 'TRX':
                return (
                    <img src={tronIcon} alt="trx"/>
                );
            case 'USDT_TRC20':
                return (
                    <img src={usdtTrcIcon} alt="USDT_TRC20"/>
                );
            case 'OMT':
                return (
                    <img src={omtIcon} alt="omt"/>
                );
            case 'OMT_BEP20':
                return (
                    <img src={omtIcon} alt="omt"/>
                );
            case 'HONEE':
                return (
                    <img src={honeeIcon} alt="honee"/>
                );
            case 'USDT_BEP20':
                return (
                    <img src={usdtBrcIcon} alt="brc"/>
                );
            case 'USDT_ERC20':
                return (
                    <img src={usdtErcIcon} alt="erc"/>
                );
            case 'anyUSDC':
                return (
                    <img src={usdcIcon} alt="usdc"/>
                );
            case 'USDC':
                return (
                    <img src={usdcIcon} alt="usdc"/>
                );
            case 'USDC_BEP20':
                return (
                    <img src={usdcIcon} alt="usdc"/>
                );
            case 'USDC_TRC20':
                return (
                    <img src={usdcIcon} alt="usdc"/>
                );
            case 'MATIC_BEP20':
                return (
                    <img src={maticIcon} alt="usdc"/>
                );
            case 'ADA_BEP20':
                return (
                    <img src={adaIcon} alt="usdc"/>
                );
            case 'Tron':
                return (
                    <img src={tronIcon} alt="trx"/>
                );
            case 'local':
                return (
                    <img src={honeeIcon} alt="honee"/>
                );
            case 'Ethereum':
                return (
                    <img src={ethIcon} alt="eth"/>
                );
            case 'BSC':
                return (
                    <img src={btcIcon} alt="btc"/>
                );
            default:
                return (
                    <img src={solIcon} alt="sol"/>
                );
        }
    }

    return (
        <div className={`${Styles.container} ${mediumPSize ? Styles.containerMediumPlus : null} ${bigSize ? Styles.containerBig : null} ${smallSize ? Styles.containerSmall : null} ${mediumSize ? Styles.containerMedium : null}`}>
            {contentHandler()}
        </div>
    );
};

export default CryptoIcon;
