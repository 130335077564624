import React, {useEffect} from "react";
import Styles from './styles.module.scss';

import CustomRouter from "../../components/customRouter";
import WalletSendContent from "../../components/walletSendContent";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getCurrentWalletAssets} from "../../store/wallet/action";



const WalletSendPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if (id){
            dispatch(getCurrentWalletAssets(id));
        }
    },[dispatch, id])

    return (
        <section className={`${Styles.container}`}>
            <WalletSendContent/>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletSendPage;