import React, {useState} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import {useNavigate} from "react-router-dom";
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";

import CustomBtn from "../../components/_UI/customBtn";





const WalletImportStep1 = () => {
    const navigate = useNavigate();

    const [mnemonic, setMnemonic] = useState('');


    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.backBtnRow}`} onClick={()=>{navigate('/wallet-add')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.areaWrap}`}>
                        <textarea
                            placeholder='Paste or enter your mnemonic, private key, or keystore'
                            onChange={(e)=>{setMnemonic(e.target.value)}}
                        />
                    </div>
                </div>
                <div className={`${Styles.mainBtnWrap}`}>
                    <CustomBtn text={'Next'} link={mnemonic.length > 5 ? '/wallet-import-2' : false} btnType={mnemonic.length > 5 ? 'wb' : 'db'}/>
                </div>
            </div>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletImportStep1;