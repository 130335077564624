import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import CustomRouter from "../../components/customRouter";
import WalletCurrentAssetsMain from "../../components/walletCurrentAssetsMain";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getCurrentWalletAssets, getWalletAssetsHistory} from "../../store/wallet/action";
import WalletCurrentAssetsHistory from "../../components/walletCurrentAssetsHistory";



const WalletCurrentAssetsPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    
    useEffect(()=>{
       if (id){
           dispatch(getCurrentWalletAssets(id));
           dispatch(getWalletAssetsHistory(id));
       } 
    },[dispatch, id]);
    
    return (
        <section className={`${Styles.container}`}>
            <WalletCurrentAssetsMain/>
            <WalletCurrentAssetsHistory/>
            <CustomRouter page='wallet'/>
        </section>
    );
};

export default WalletCurrentAssetsPage;