import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {getWallets, setCurrentBlockchain} from "../../store/wallet/action";



const WalletSwitchBlockchains = (props) => {
    const {network, setNetwork} = props;
    const dispatch = useDispatch();

    const allBlockchains = useSelector(store => store.wallet.allBlockchain);
    const currentBlockchain = useSelector(store => store.wallet.currentBlockchain);


    console.log(currentBlockchain)
    console.log(allBlockchains)
    console.log(network)

    const changeBlockchainHandler = (id) =>{
        localStorage.setItem('owt-cb_id', id);
        dispatch(setCurrentBlockchain(id));
    }

    useEffect(()=>{
        if (allBlockchains && currentBlockchain){
            allBlockchains.blockchains?.forEach((item)=>{
                if (item.id === currentBlockchain){
                    setNetwork(item?.network);
                }
            })
        }
    }, [allBlockchains, currentBlockchain, setNetwork])
    
    useEffect(()=>{
        if (currentBlockchain && network){
            dispatch(getWallets(currentBlockchain, network?.id))
        }
    },[dispatch, network]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                {allBlockchains ? allBlockchains?.blockchains.map((item, index)=>{
                    return (
                        <div className={`${Styles.contentWrapItem} ${item.id === currentBlockchain ? Styles.contentWrapItemActive : null}`}
                             key={'allBlockchains' + index}
                             onClick={()=>{
                                 changeBlockchainHandler(item.id)
                             }}
                        >
                            <CryptoIcon short_name={item.name} mediumSize={true}/>
                        </div>
                    )
                }) : null}
            </div>
        </section>
    );
};

export default WalletSwitchBlockchains;
