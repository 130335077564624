import React from "react";
import Styles from './styles.module.scss'
import listArrowIcon from "../../theme/assets/svg/omt_list_arrow_icon.svg";
import addWalletIcon from '../../theme/assets/svg/omt_add_wallet_icon.svg';
import {useNavigate} from "react-router-dom";



const WalletSwitchHeader = () => {
    const navigate = useNavigate();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentWrapItem}`} onClick={()=>{navigate('/wallet')}}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={listArrowIcon} alt="back arrow"/>
                    </div>
                    <div className={`${Styles.title4}`}>Back</div>
                </div>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={addWalletIcon} alt="add wallet"/>
                    </div>
                    <div className={`${Styles.title4}`} onClick={()=>{navigate('/wallet-add')}}>Add wallet</div>
                </div>
            </div>
        </section>
    );
};

export default WalletSwitchHeader;
