import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {changeAccountStatus, getMainWalletData} from "../../store/wallet/action";
import CustomRouter from "../../components/customRouter";
import WalletSettings from "../../components/walletSettings";
import WalletNetworkModal from "../../components/walletNetworkModal";
import WalletMain from "../../components/walletMain";
import WalletAssets from "../../components/walletAssets";



const WalletPage = () => {
    const dispatch = useDispatch();
    const accountStatus = useSelector(store => store.wallet.accountStatus);
    // console.log(window.ethereum)

    const reloadHandler = () =>{
        dispatch(getMainWalletData(null, true));
    }

    useEffect(()=>{
        if (accountStatus){
            dispatch(changeAccountStatus(false));
        } else {
            dispatch(getMainWalletData(null, true));
        }
    },[dispatch]) // eslint-disable-line

    return (
        <section className={`${Styles.container}`}>
            <WalletSettings reload={reloadHandler}/>
            <WalletMain/>
            <WalletAssets/>
            <CustomRouter page='wallet'/>
            <WalletNetworkModal/>
        </section>
    );
};

export default WalletPage;