import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import CustomBtn from "../_UI/customBtn";


const Authorization = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.paddingWrap}`}>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.btnWrap}`}>
                        <CustomBtn text={t('Создать кошелек')} link='/user-agreement' btnType={'wb'}/>
                        <CustomBtn text={t('Импортировать кошелек')} link='/import'/>
                    </div>
                    <div className={`${Styles.customLink}`}>You can also <span>pair Ledger hardware wallet</span></div>
                </div>
            </div>
        </section>
    );
};

export default Authorization;
