import React, {useState} from "react";
import Styles from './styles.module.scss'
import CustomInput from "../../components/_UI/customInput";
import CustomBtn from "../../components/_UI/customBtn";
import CustomRouter from "../../components/customRouter";
import {useDispatch, useSelector} from "react-redux";
import {login, loginFailure} from "../../store/main/action";




const LoginPage = () => {
    const dispatch = useDispatch();
    const loginErr = useSelector(store => store.main.loginErr);

    const [password, setPassword] = useState('');

    const mainBtnHandler = () =>{
        dispatch(login({uuid: localStorage.getItem('owt-uuid'), password: password}));
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.formWrap}`}>
                    <div className={`${Styles.title5}`}>Please enter your password</div>
                    <div className={`${Styles.inputWrap}`}>
                        <CustomInput
                            type='password'
                            title='Password'
                            placeholder='Enter a password'
                            value={password}
                            setValue={setPassword}
                            standardErrData={{message: loginErr, action: loginFailure, name: 'password'}}
                        />
                    </div>
                    <div className={`${Styles.btnWrap}`}>
                        <CustomBtn text={'Cancel'} link='/'/>
                        <CustomBtn text={'Confirm'} link='/' btnType={'wb'} action={mainBtnHandler}/>
                    </div>
                </div>
            </div>
            <CustomRouter page='sign-in'/>
        </section>
    );
};

export default LoginPage;